import React, { useEffect, useState } from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCoupons, useUser } from '../../../hooks';
import { Button } from '../button';

export const Coupon = ({code, setCode, business, setBusiness, discount, setDiscount, price, setPrice, priceInitial}) => {

    const { validateCoupon } = useCoupons();
    const { store } = useUser();

    const [showOption, setShowOption] = useState(true);
    const [textValidationCode, setTextValidationCode] = useState('');

    useEffect(() => {
        console.log(store.user)
        if(store.user?.SCupon_Descuento && store.user?.SCupon_Descuento !== '') {
            setShowOption(false)
            setCode(store.user.SCupon_Descuento)
            applyCoupon(store.user.SCupon_Descuento)
        }
    }, [store.user])

    const handleChange = ({target}) => {
        setCode(target.value)
    }

    const applyCoupon = async(coupon = code) => {
        if(coupon !== '') {
            const resCoupon = await validateCoupon(coupon);
            if(resCoupon.status === 200) {
                setBusiness(resCoupon.data.business)
                setDiscount(resCoupon.data.discount)
                setTextValidationCode('')
            } else if(resCoupon.status === 230) {
                setBusiness('')
                setDiscount(0)
                setTextValidationCode('El cupón ingresado no es válido')
                setPrice(priceInitial)
            } else if(resCoupon.status === 231) {
                setBusiness('')
                setDiscount(0)
                setTextValidationCode('El cupón ingresado no es válido')
                setPrice(priceInitial)
            }
        }
    }

    return (
        <>
            <div className={`container-input input-group ${textValidationCode !== '' ? 'mb-4' : discount > 0 ? (business !== null ? 'mb-5' : 'mb-4') : 'mb-4'}`}>
                {
                    showOption ? (
                        <Button className={"cancel-disabled-button w-100 button-select-modal"} onClick={() => setShowOption(false) } label="Tengo un código" />
                    ) : (
                        <>
                        <div className={`container-coupon position-relative w-100`}> 
                            <input id="input-code-discount" type="text" className="form-control input" onChange={handleChange} placeholder="Ingresa tu código" value={code} autoComplete='off' maxLength={30} />
                            { textValidationCode !== '' ? <p style={{top: '28px'}} className="message-error mt-2">{textValidationCode}</p> : business !== '' && (
                                 <div style={{top: '36px'}} className='container-title position-absolute w-100 mt-2'>
                                    <p className='price fw-bold m-0'>{business}</p>
                                    <p className='price fw-bold m-0'>El valor a pagar <span className='price-discount'>hoy es {price}</span></p>
                                </div>
                            )}
                            <div className='w-100 position-relative'>
                                <Button className={`w-100 button-select-modal apply-coupon-void h-100 ${code !== '' ? 'apply-coupon' : 'cancel-disabled-button'}`} onClick={() => applyCoupon() } label='Aplicar' disabled={false} />
                            </div>
                        </div>
                        </>
                    )
                }
            </div>
        </>
    )
}