import React, { useEffect, useState } from 'react';
import { RadioPersonality } from '..';

export const Plan = ({data, setPlan, index, lineThrough, bdb = false}) => {
    
    const [showDescription, setShowDescription] = useState(false)

    useEffect(() => {
        let doc = document.getElementById('plan-0')
        if(doc) doc.checked = true
        if(showDescription || bdb) {
            document.getElementById(`plan-description-${index}`).innerHTML = data.SDescripcion
        } else {
            document.getElementById(`plan-description-${index}`).innerHTML = ''
        }
    }, [showDescription])

    return (
        <>
            <div className='container-plan'>
                {
                    !bdb && (
                        <>
                            <div className='name-radio'>
                                <RadioPersonality
                                    name="plans" 
                                    id={`plan-${index}`} 
                                    onChange={() => setPlan(data)}
                                    classNameradioMarked='primary'
                                />
                                <label htmlFor={`plan-${index}`}>
                                    <h4 className='text-center text text-uppercase fw-bold'>{data.SNombre}</h4>
                                </label>
                            </div>
                            <p className='price m-0'>Precio normal <span className='text-decoration-line-through'>{data.priceStr}</span></p>
                            <p className='price fw-bold m-0'>Oferta especial plan {data.SNombre.toLowerCase()} <span className={`price-discount ${lineThrough ? 'text-decoration-line-through' : ''}`}>{data.priceDiscountStr}</span></p>
                            <p className='price'>12 reportes uno cada mes.</p>
                        </>
                    )
                }
                { !bdb && <p className='show-description text-decoration-underline' onClick={() => setShowDescription(!showDescription)}>Ver más...</p>}
                {/* {
                    showDescription && ( */}
                        <div className='container-description'>
                            <div className='description m-0' id={`plan-description-${index}`}></div>
                        </div>
                    {/* )
                } */}
            </div>
        </>
    )
}