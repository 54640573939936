import { CLEAR_SITE, SET_SITE } from "../constants";

const initialState = {};

const siteReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_SITE:
      return action.site;
    case CLEAR_SITE:
      return initialState;

    default:
      return state;
  }
};

export default siteReducer;