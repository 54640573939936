import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import "./message.scss";
import { Button } from '../UI/components';


export const success = (message) => messageSuccessOrError(message, 'success')

export const error = (message, time) => messageSuccessOrError(message, 'error', time)

export const alert = (message, closed) => MessageInfo(message, 'info', 30000, closed)


const messageSuccessOrError = (message, type, time, closed = false) => {
  return (
    swal({
      text: message,
      icon: type,
      buttons: false,
      className: 'swal-modal-success-or-error',
      timer: time ?? 2000,
      closed: closed
    })
  )
};

export const MessageInfo = ({title, message,onClick, className, button2 = false, onClick2, label2, label = "Cerrar", newStyle = false}) => {

  return (
    <>
      <div className="container-alert-message container-alert-message--show-modal" tabIndex="-1">
        <div className={`contain-alert-message ${className ?? ''}`} role="dialog" aria-modal="true">
          <div className="container-title">
            <h4 className="title">{title}</h4>
          </div>
          <FontAwesomeIcon className="icon" icon={faExclamationTriangle} />
          <p className="text">{message}</p>
          <div className="d-flex flex-column align-items-center justify-content-center w-100" style={{gridGap: '10px'}}>
            {
              button2 && <Button className="button-info-alert cancel-disabled-button" onClick={onClick2} label={label2} /> 
            }
            {
              newStyle ? (
                <a className="to-return cursor-pointer" role="button" onClick={onClick}>{label}</a>
              ) : (
                <Button className="button-info-alert button-normal" onClick={onClick} label={label} />
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}