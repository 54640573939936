import instance from './axios';

export const getUserOfSura = async(doc) => {
    try {
        const token = sessionStorage.getItem('token')
        return await instance(`sura/user/${doc}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
    } catch(err) {
        console.error(err)
    }
}