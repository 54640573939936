import instance from '../services/axios';

export const useSelects = () => {
    
    const token = sessionStorage.getItem('token')

    const getTypeDocuments = async () => {
        try {
            return await instance.get(`selects/type-documents`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        } catch(err) {
            console.log(err)
        }
    }
    
    const getIndicativos = async () => {
        try {
            return await instance.get(`selects/indicatives`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    const getCountries = async () => {
        try {
            return await instance.get(`selects/countries`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    const getTipology = async () => {
        try {
            return await instance.get(`selects/tipology`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    const getMetersRange = async () => {
        try {
            return await instance.get(`selects/meters-range`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    return { getTypeDocuments, getIndicativos, getCountries, getTipology, getMetersRange }
}