import instance from '../services/axios';

export const usePlan = () => {

    const token = sessionStorage.getItem('token')

    const getPlan = async (plan) => {
        try {
            return await instance.get(`plan/coche/${plan}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    const getPlans = async (plans) => {
        try {
            return await instance.post(`plans`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: plans
            })
        } catch(err) {
            console.log(err)
        }
    }

    const getInfoTransactionWompi = async(reference) => {
        try {
            return await instance.get(`payments/transaction-wompi/${reference}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    const getInfoTransactionWompiForId = async(id) => {
        try {
            return await instance.get(`payments/transaction-wompi-id/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    const upgrade = async(plan, path, propToken) => {
        try {
            return await instance(`payments/upgrade`, {
                method: "post",
                headers: {
                    'Authorization': `Bearer ${propToken ?? token}`
                },
                data: {
                    plan: plan,
                    path: path
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    const getPlanUpgrade = async(plan, propToken) => {
        try {
            return await instance.get(`payments/get-plan-upgrade/${plan}`, {
                headers: {
                    'Authorization': `Bearer ${propToken}`
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    return { getPlan, getPlans, getInfoTransactionWompi, getInfoTransactionWompiForId, upgrade, getPlanUpgrade }
}