import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp, useSelects, useSite, useUser } from '../../../../../hooks';
import { emojiPray } from '../../../../assets/icons';
import { Button, SelectModal } from '../../../../components';

export const Phase1SI = () => {

    const { getTipology, getMetersRange } = useSelects();
    const { store, updateUser } = useUser();
    const { createSite, updateSite } = useSite();
    const { setRefreshFn } = useApp();

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [tipology, setTipology] = useState('')
    const [tipologies, setTipologies] = useState([])
    const [metersRange, setMetersRange] = useState([])
    const [meterRange, setMeterRange] = useState({ICodigo: '', name: '', IRango1: '', IRango2: ''})
    const [meters, setMeters] = useState('')
    const [quantityPeople, setQuantityPeople] = useState('')

    const [showOptionsTipology, setShowOptionsTipology] = useState(false)
    const [showOptionsMetersRange, setShowOptionsMetersRange] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setRefreshFn(true)
    }, [])

    const fetchData = useCallback(async() => {
        const resTipology = await getTipology()
        const resMeters = await getMetersRange()
        const res = resTipology.data.map(element => ({ICodigo: element.ICodigo, name: element.STipologia}))
        const resM = resMeters.data.map(element => ({ICodigo: element.ICodigo, name: `${element.IRango1} - ${element.IRango2}`, IRango1: element.IRango1, IRango2: element.IRango2}))
        resM.unshift({ICodigo: 'N/A', name: 'Sé con exactitud:'})
        setTipologies(res)
        setMetersRange(resM)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        const docButton = document.getElementById('button-submit-atomic')
        if(tipology !== '' && ((meters !== '' && !isNaN(meters) && meterRange.ICodigo === 'N/A') || (meterRange.ICodigo !== 'N/A' && meterRange.ICodigo !== '')) && quantityPeople !== '') {
            docButton?.classList.remove('button-atomic-disabled')
            setButtonDisabled(false)
        } else {
            docButton?.classList.add('button-atomic-disabled')
            setButtonDisabled(true)
        }
    }, [quantityPeople, meters, tipology, meterRange])

    const handleChangeTipology = (value) => {
        setTipology(value)
        setShowOptionsTipology(false)
    }

    const handleChangeMetersRange = (value) => {
        setMeterRange(value)
        setShowOptionsMetersRange(false)
    }

    const handleChangeMeters = ({target}) => {
        if(target.value < 100000) setMeters(parseInt(target.value))
    }

    const handleChangePeople = ({target}) => {
        setQuantityPeople(parseInt(target.value))
    }

    const submit = async(event) => {
        event.preventDefault()
        try {
            const route = sessionStorage.getItem('route')
            if(route === 'plan-gratin' || store.user?.contract) {
                await createSite({ path: 'site-information/phase-2', SNombre: tipology.name, ICod_Ciudad: 83, SDireccion: 'Carrera 48C 10 sur 74', ICod_Tipologia: tipology.ICodigo, IArea: meterRange.ICodigo === 'N/A' ? meters : parseInt((meterRange.IRango1 + meterRange.IRango2) / 2), INum_Personas: quantityPeople, ICodigo: parseInt(store.site.ICodigo)})
            } else {
                await updateSite({ ICod_Tipologia: tipology.ICodigo, SNombre: tipology.name, IArea: meterRange.ICodigo === 'N/A' ? meters : parseInt((meterRange.IRango1 + meterRange.IRango2) / 2), INum_Personas: quantityPeople, ICodigo: parseInt(store.site.ICodigo)})
                await updateUser({path: 'site-information/phase-2', ICodigo: parseInt(store.user?.ICodigo)})
            }
            navigate("../../site-information/phase-2")
        } catch(err) {
            console.error(err)
        }
    }

    return (
        <>
            <section className="phase-3">
                <h5 className="title"><strong>Permítenos</strong> conocer tu hogar.</h5>
                <p className="text mt-2 mb-3"><strong>¿Para qué?</strong> Para calcular tu consumo promedio, compararte con otros hogares y ayudarte a ahorrar
                </p>
                <div className='container-select-modal'>
                    { showOptionsTipology && <SelectModal data={tipologies} className={'top-0'} selected={handleChangeTipology} hideModal={() => setShowOptionsTipology(false)} /> }
                </div>
                <div className='w-100 position-relative'>
                    <Button className={"cancel-disabled-button w-100 mb-2 button-select-modal"} onClick={() => setShowOptionsTipology(true) } label={tipology !== '' ? tipology.name.toLowerCase() : "Toca aquí y selecciona tu tipo de hogar"} />
                    <FontAwesomeIcon className='icon-down' icon={faAngleDown} onClick={() => setShowOptionsTipology(true) } />
                </div>
                <div className="container-input input-group mb-3 d-flex flex-column">
                    <label className='label'>Contándote, ¿Cuántos viven en tu hogar?</label>
                    <input type="number" className="input form-control rounded-5 text-center rounded-5" onChange={handleChangePeople} value={quantityPeople} autoComplete='off' />
                </div>
                <div className='border-si-phase-1'></div>
                <p className="text mt-2 mb-3">¿Cuántos metros cuadrados (mt2) tiene el hogar registrado?</p>
                <div className='w-100 position-relative'>
                    <Button className={"cancel-disabled-button w-100 mb-2 button-select-modal"} onClick={() => setShowOptionsMetersRange(true) } label={meterRange.ICodigo !== '' ? meterRange.name.toLowerCase() : "Toca aquí y escoge los mt2"} />
                    <FontAwesomeIcon className='icon-down' icon={faAngleDown} onClick={() => setShowOptionsMetersRange(true) } />
                </div>
                <div className='container-select-modal'>
                    { showOptionsMetersRange && <SelectModal data={metersRange} className={'bottom-0'} selected={handleChangeMetersRange} hideModal={() => setShowOptionsMetersRange(false)} /> }
                </div>
                {
                    meterRange.ICodigo === "N/A" && (
                        <div className="container-input input-group mb-2">
                            <input type="number" className="input form-control rounded-5 text-center mb-1" onChange={handleChangeMeters} value={meters} placeholder="¿Cuántos mt2 tiene?" autoComplete='off' />
                        </div>
                    )
                }
                <Button id="button-submit-atomic" disabled={buttonDisabled} onClick={submit} label="Siguiente" />
            </section>
            {
                store.user?.ICod_Plan === 2 ? (
                    <div className='container-progress-gratin'>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage progress-active'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                    </div>
                ) : (  
                    <div className='container-progress-payment'>
                        <span className='progress-stage progress-active'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                    </div>
                )
            }
        </>
    )
}