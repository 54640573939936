/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button, Icon } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { useUser, useApp, useSelects, usePlan } from '../../../../../hooks';
import { emojiEye } from '../../../../assets/icons';
import { useVerifications } from '../../../../../utils/verifications.utils';
import { error, MessageInfo } from '../../../../../services/message';
import Recaptcha from 'react-google-invisible-recaptcha';
import { getUserOfSura } from '../../../../../services/sura';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { InvoiceEPM } from '../../../../assets/images';

export const SignInPaymentSura = ({ unregisteredPayment = false, unregistered = false, bdb = false }) => {

    const messageNotRegister = <><p>Aún no estás registrado con nosotros, da clic <a href='https://greenbi.co/iniciar/'>aqui</a> para que selecciones tu plan e inicies tu proceso.</p></>

    const { store, createUserWithEmailPayment, updateUserPayment, getUserWithReference, getUserWithEmail } = useUser();
    const { clearData, setRefreshFn, hideLoading } = useApp();
    const { verificationEmail } = useVerifications();
    const { getIndicativos } = useSelects();
    const { getPlan, getPlanUpgrade, getInfoTransactionWompiForId } = usePlan();

    // Cedula
    const [doc, setDoc] = useState('');
    const [textValidationDoc, setTextValidationDoc] = useState('');

    // Number phone
    const [number, setNumber] = useState('');
    const [number2, setNumber2] = useState('');
    const [textValidationNumber2, setTextValidationNumber2] = useState('');
    const [textValidationNumber, setTextValidationNumber] = useState('');
    const [indicative, setIndicative] = useState({})
    const [processFinished, setProcessFinished] = useState(false)

    // Plan
    const [plan, setPlan] = useState({})
    
    // Contract
    const [contract, setContract] = useState('')
    const [textValidationContract, setTextValidationContract] = useState('')
    
    // Payment
    const [paymentDeclined, setPaymentDeclined] = useState(false)
    const [stateUnregisteredPayment, setStateUnregisteredPayment] = useState(false)
    const [upgrade2, setUpgrade2] = useState(false)

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [email, setEmail] = useState('')
    const [showAlertInfo, setShowAlertInfo] = useState(false)

    const [textValidationEmail, setTextValidationEmail] = useState('')

    const [messageAlert, setMessageAlert] = useState(<></>)

    const recaptcha = useRef()

    const navigate = useNavigate();

    const fetchDataPaymentRejected = useCallback(async() => {
        let params = (new URL(window.document.location)).searchParams;
        let id = params.get('id');
        let paramEmail = params.get('email');
        if(id) {
            const transaction = await getInfoTransactionWompiForId(id)
            const { status, reference } = transaction?.data?.data
            if(status && reference) {
                if(status === 'APPROVED') {
                    navigate("../../congratulations-sura")
                } else {
                    await getUserWithReference(reference)
                    setPaymentDeclined(true)
                    setMessageAlert(messageNotRegister)
                    error(`Transaccion ${status === 'DECLINED' ? 'rechazada' : status === 'VOIDED' ? 'anulada' : 'error'}`, 3000)
                }
            }
        } else if(paramEmail) {
            await getUserWithEmail(paramEmail)
        }
    }, [])

    useEffect(() => {
        fetchDataPaymentRejected()
    }, [fetchDataPaymentRejected])

    useEffect(() => {

        if(JSON.stringify(store.user) !== '{}') {
            if(store.user.SEstadoPago !== 'APPROVED') {
                if(store.user.correo) setEmail(store.user.correo)
                if(store.user.STelefono) {
                    setNumber(store.user.STelefono)
                    setNumber2(store.user.STelefono)
                }
                if(store.user?.contract) setContract(store.user.contract)
            } else {
                setProcessFinished(true);
                setTextValidationEmail("Este correo ya terminó su registro")
            }
        } else {
            setNumber('')
        }

    }, [store.user, store.site, upgrade2])

    useEffect(() => {
        setRefreshFn(false)
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('token')
        clearData()
    }, [])

    const fetchData = useCallback(() => {

        getIndicativos()
            .then((response) => {
                const obj = response.data.find(element => element.INum_Indicativo === '+57')
                setIndicative(obj)
            })
            .catch((err) => {
                console.log(err)
            })
        
        // Wompi
        document.getElementById('button-submit-atomic-payment')?.classList.remove('button-atomic-disabled')
        const script = document.createElement("script");
        script.src = "https://checkout.wompi.co/widget.js";
        script.async = true;
        document.body.appendChild(script);

        getPlan(1)
            .then(({data}) => {
                setPlan(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        
        const arr = doc.split('')
        const arrNumber = number.split('')
        const arrNumber2 = number2.split('')
        const validationEmail = verificationEmail(email)
        const docButton = document.getElementById('button-submit-atomic')

        if(arrNumber.length === 10 && validationEmail && (contract > 0 || contract.length > 0) && docButton !== null && docButton !== undefined && !processFinished) {
            setButtonDisabled(false)
            docButton.classList.remove('button-atomic-disabled')
        } else {
            setButtonDisabled(true)
            docButton.classList.add('button-atomic-disabled')
        }

        // Email
        if(validationEmail && store.user.SEstadoPago !== 'APPROVED') {
            setTextValidationEmail("")
        }

        // number
        if(arrNumber.length === 10) {
            setTextValidationNumber("")
        }
        
        if(arrNumber.length === 10) {
            setTextValidationNumber("")
        }

        if(arrNumber2.length === 10) {
            setTextValidationNumber2("")
        }

        if(number === number2) {
            setTextValidationNumber2('')
        }
        
        // cedula
        if(arr.length > 4) {
            setTextValidationDoc("")
        }

    }, [doc, email, number, number2, contract, processFinished])


    // Cedula
    const onBlurDoc = async() => {
        const arr = doc.split('')
        if(arr.length <= 4) {
            setTextValidationDoc("La cantidad mínima de caracteres son 5")
        } else {
            const { isUserOfSura } = await getUserOfSura(doc);
            if(!isUserOfSura) {
                window.location.replace('/first-steps/phase-1?mode=plan-coche');
            }
        }
    }

    const handleChangeDoc = ({target}) => {
        setDoc(target.value)
    }

    // Email
    const handleChangeEmail = ({ target }) => {
        const value = target.value.toLowerCase()
        setEmail(value)
    }

    const onBlurEmail = async() => {
        const validationEmail = verificationEmail(email)
        if(!validationEmail) {
            setTextValidationEmail("Escribe correctamente tu correo")
        } else {
            const resUser = await getUserWithEmail(email)
            if(resUser.status === 200 && resUser?.data?.user?.SEstadoPago === 'APPROVED') {
                setProcessFinished(true);
                setTextValidationEmail("Este correo ya terminó su registro")
            } else {
                setProcessFinished(false);
                setTextValidationEmail("")
            }
        }
    }

    // Number phone
    const onBlurNumber = () => {
        const arr = number.split('')
        if(number !== number2 && number2 !== '') {
            setTextValidationNumber2("Los números no coinciden")
        } else {
            setTextValidationNumber2("")
        }
        if(arr.length !== 10) {
            setTextValidationNumber("Ingresa 10 números")
        }
    }

    const onBlurNumber2 = () => {
        const arr = number2.split('')
        if(number !== number2) {
            setTextValidationNumber2("Los números no coinciden")
        } else {
            setTextValidationNumber2("")
        }
        if(arr.length !== 10) {
            setTextValidationNumber2("Ingresa 10 números")
        }
    }

    const handleChangeNumber = ({target}) => {
        const arr = target.value.split('')
        if(arr.length <= 10) {
            setNumber(target.value)
        }
    }

    const handleChangeNumber2 = ({target}) => {
        const arr = target.value.split('')
        if(arr.length <= 10) {
            setNumber2(target.value)
        }
    }

    // Contract
    const handleChangeContract = ({target}) => {
        const arr = target.value.split('')
        if(arr.length <= 20) {
            setContract(target.value)
        }
    }

    const recaptchaFn = () => {
        recaptcha.current.callbacks.reset()
        recaptcha.current.callbacks.execute()
            .then(() => {
                const objRegister = {
                    cedula: doc,
                    email: email,
                    indicative: indicative,
                    number: number,
                    contract: contract,
                    plan: plan,
                    reference:  store.user?.SReferenciaPago,
                    stateUnregisteredPayment:  stateUnregisteredPayment,
                    upgrade:  upgrade2,
                    path: store.user?.path
                }
                sessionStorage.setItem('dataRegister', JSON.stringify(objRegister))
                hideLoading()
            })
    }

    // Payment
    const payment = async(reference, data, propToken) => {
        let checkout = new window.WidgetCheckout({
            currency: 'COP',
            amountInCents: parseInt(data.plan.SValor - (data.plan.SValor * .1)) * 100, 
            reference:  reference,
            publicKey: process.env.REACT_APP_PUBLIC_KEY_WOMPI,
            redirectUrl: `${process.env.REACT_APP_URL_FRONT}first-steps/phase-1?mode=sura`,
            customerData: {
                email: data.email,
                // fullName: data.name,
                phoneNumber: data.number,
                phoneNumberPrefix: '+57'
            }
        })
        if(paymentDeclined) {
            if(data.upgrade) await getPlanUpgrade(data.plan.ICodigo, propToken)
            checkout.open( async ( result ) => {
                //Guardar en la base de datos
                let transaction = result.transaction
                if(transaction.status === "APPROVED") {
                    navigate("../../congratulations-sura")
                } else {
                    setPaymentDeclined(true)
                    setMessageAlert(messageNotRegister)
                    error(`Transaccion ${transaction.status === 'DECLINED' ? 'rechazada' : transaction.status === 'VOIDED' ? 'anulada' : 'error'}`, 3000)
                    console.error(`Transaccion ${transaction.status}`)
                }
            })
        } else {
            if(data.upgrade) await getPlanUpgrade(data.plan.ICodigo, propToken)
            updateUserPayment({SReferenciaPago: reference}, propToken)
                .then(() => {
                    checkout.open( async ( result ) => {
                        //Guardar en la base de datos
                        let transaction = result.transaction
                        if(transaction.status === "APPROVED") {
                            navigate("../../congratulations-sura")
                        } else {
                            setPaymentDeclined(true)
                            setMessageAlert(messageNotRegister)
                            error(`Transaccion ${transaction.status === 'DECLINED' ? 'rechazada' : transaction.status === 'VOIDED' ? 'anulada' : 'error'}`, 3000)
                            console.error(`Transaccion ${transaction.status}`)
                        }
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const submit = () => {
        const dataRegister = sessionStorage.getItem('dataRegister')
        const dataRegisterParse = JSON.parse(dataRegister)
        console.log(dataRegisterParse)
        sessionStorage.removeItem('dataRegister')
        createUserWithEmailPayment({ SCedula: dataRegisterParse.cedula, email: dataRegisterParse.email, ICod_Indicativo: dataRegisterParse.indicative.ICodigo, STelefono: dataRegisterParse.number, contract: dataRegisterParse.contract, plan: dataRegisterParse.plan.ICodigo, unregisteredPayment: unregisteredPayment, unregisteredPayment2: dataRegisterParse.upgrade, unregistered: unregistered, bdb: bdb, sura: true })
            .then(async(response) => {
                if(response.status === 201 || response.status === 280) {
                    sessionStorage.setItem('email', dataRegisterParse.email)
                    sessionStorage.setItem('token', response.token)
                    sessionStorage.setItem('id', response.id)
                    if(dataRegisterParse.plan.SValor > 0) {
                        const date = new Date()
                        const string = date.toLocaleString()
                        const reference = btoa(string)
                        payment(response.data?.SReferenciaPago ? response.data?.SReferenciaPago : reference, dataRegisterParse, response.token)
                    } else {
                        navigate("../../congratulations-sura")
                    }
                } else if(response.status === 235 || response.status === 236) {
                    setTextValidationEmail('Este correo ya terminó su registro')
                } else if(response.status === 242) {
                    sessionStorage.setItem('route', 'plan-gratin')
                    sessionStorage.setItem('token', response.token)
                    navigate(`../../${response.path}`)
                } else if(response.status === 241) {
                    sessionStorage.setItem('route', `plan-${response.plan === 1 ? 'coche' : 'avion'}`)
                    sessionStorage.setItem('token', response.token)
                    if(response.path.includes('mode=')) {
                        window.location = `${process.env.REACT_APP_URL_FRONT}${response.path}`
                    } else {
                        navigate(`../../${response.path}`)
                    }
                } else if(response.status === 240) {
                    setMessageAlert(messageNotRegister)
                    setShowAlertInfo(true)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    return (
        <>
            <section className="sign-in-payment">
                <img className='emoji' src={emojiEye} alt="" />
                <h5 style={{ fontWeight: 'bold', marginBottom: '2rem' }}>Ingresa tus datos básicos</h5>
                <div className="form">

                    {/* Cedula */}
                    <div>
                        <div className="container-input input-group mb-4">
                            <input type="text" data-bs-toggle="tooltip" data-bs-placement="top" title="Ingresa tu número de cédula" className="form-control rounded-3 input" maxLength={20} onBlur={onBlurDoc} onChange={handleChangeDoc} placeholder="Número de cédula" value={doc} autoComplete='off' />
                            { textValidationDoc !== '' && <p style={{ bottom: '-2rem' }} className="message-error mt-2">{textValidationDoc}</p> }
                        </div>
                    </div>

                    {/* email */}
                    <div className='container-input input-group mb-4'>
                        <input id="input-email-register" type="text" className="form-control rounded-3 input" onBlur={() => onBlurEmail()} onChange={handleChangeEmail} placeholder="ejemplo@mail.com" value={email} autoComplete='off' />
                        { textValidationEmail !== '' && <p className="message-error message-error-verification-email">{textValidationEmail}</p> }
                    </div>

                    {/* Number phone */}
                    <div className={`input-gro position-relative container-phone ${textValidationNumber !== '' ? 'mb-4' : 'mb-0'}`}>
                        <div className="container-input">
                            <input type="number" className={`input form-control rounded-3 text-center`} onBlur={onBlurNumber} onChange={handleChangeNumber} placeholder="Ingresa tu celular" value={number} autoComplete='off' />
                            { textValidationNumber !== '' && <p className="message-error message-error-number-phone">{textValidationNumber}</p> }
                        </div>
                    </div>
                    <div className='input-gro position-relative container-phone mb-4'>
                        <div className="container-input">
                            <input type="number" className={`input form-control rounded-3 text-center`} onBlur={onBlurNumber2} onChange={handleChangeNumber2} placeholder="Confirma tu celular" value={number2} onPaste={(e) => {e.preventDefault()}} autoComplete='off' />
                            { textValidationNumber2 !== '' && <p className="message-error message-error-number-phone">{textValidationNumber2}</p> }
                        </div>
                    </div>

                    {/* Contract */}
                    <div class={`input-gro position-relative container-address rounded-5 ${textValidationContract !== '' ? 'mb-4' : 'mb-2'}`}>
                        <div className="container-input">
                            <input type="text" className={`input form-control rounded-3 text-center`} onChange={handleChangeContract} placeholder="Ingresa el número de contrato de EPM" value={contract} maxLength={50} autoComplete='off' />
                            <Icon icon={faInfoCircle} className='icon-popup-contract' />
                            <img className='example-invoice-epm' src={InvoiceEPM} alt='Factura de EPM'/>
                            { textValidationContract !== '' && <p className="message-error message-error-address">{textValidationContract}</p> }
                        </div>
                    </div>

                    <Button id={'button-submit-atomic'} style={{marginTop: '12px'}} onClick={recaptchaFn} label={plan?.price === 0 ? 'Siguiente' : 'Enviar'} disabled={buttonDisabled} />
                    <Recaptcha
                        ref={recaptcha}
                        sitekey="6LcAe6kdAAAAAE9MfRhlYwgHmC4wBj1QaWTB7BTJ"
                        onResolved={submit}
                        badge='bottomleft'
                    />
                </div>
                <p className="text-center mt-2 text-terms-and-conditions" for="text-terminos-y-condiciones">Al hacer clic en siguiente, acepta nuestros <a className="d-inline link-terms-and-conditions" href={`https://energymaster.co/${process.env.REACT_APP_TERMINOS_CONDICIONES}`} download>Términos, Política de datos y Política de cookies.</a></p>
                {
                    showAlertInfo && (
                        <MessageInfo onClick={() => setShowAlertInfo(false)} title={'Pilas'} message={messageAlert} />
                    )
                }
            </section>
        </>
    )
}