import instance from '../services/axios';

export const useSiigo = () => {
    
    const token = sessionStorage.getItem('token')

    const billingSiigo = async (req) => {
        return await instance(`siigo`, {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: req
        })
    }

    return { billingSiigo }
}