import instance from '../services/axios';

export const useActiveCampaign = () => {

    const token = sessionStorage.getItem('token')

    const completeInfoSite = async (req) => {
        return await instance(`active-campaign/info-site`, {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: req
        })
    }

    const completeInvoices = async (req) => {
        return await instance(`active-campaign/invoice`, {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: req
        })
    }

    const completeCP = async (propToken) => {
        return await instance(`active-campaign/cp`, {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${propToken ?? token}`
            }
        })
    }

    const paymentDeclined = async (data) => {
        return await instance(`active-campaign/declined`, {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: data
        })
    }

    return { completeInfoSite, completeInvoices, completeCP, paymentDeclined }
}