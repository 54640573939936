import React from 'react';
import { Main } from './UI/views/main';
import { ReduxProvider } from './providers/redux.provider';
import { AppProvider } from './providers/app.provider';
import './App.scss';


const App = () => {
  return (
    <ReduxProvider>
      <AppProvider>
        <Main />
      </AppProvider>
    </ReduxProvider>
  );
}

export default App;
