import React from 'react';
import './styles.scss';

export const SelectModal = ({id, data = [], dataOriginal, setData, hideModal = () => {}, selected = () => {}, className, classNameOption, withoutCapitalize = false, searchFilter = false}) => {

    const lengthData = data.length - 1

    const handleChangeSearch = ({target}) => {
        const dataFiltered = dataOriginal.filter(element => element.name.toUpperCase().includes(target.value.toUpperCase()))
        setData(dataFiltered)
    }

    return (
        <>
            <div className='bg-select-modal' onClick={hideModal}></div>
            <div id={id} className={`select-modal d-flex flex-column align-items-center ${className ?? ''}`} tabindex="-1" aria-labelledby="exampleSmallModalLabel" aria-hidden="true">
                {
                    searchFilter && <input type="text" className='input form-control pt-1 pb-1 rounded-pill text-center w-75 mb-2' onChange={handleChangeSearch} placeholder='Buscar' autoComplete='off' />
                }
                <div className='list'>
                    {
                        data.map((element, index) => 
                            <>
                                <span className={`${withoutCapitalize ? 'option-without-capitalize' : 'option'} ${classNameOption ?? ''}`} onClick={() => selected(element)}>{element.name}</span>
                                <span className={`${index === lengthData ? '' : 'border-bottom-option'}`}></span>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}