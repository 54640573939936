import instance from '../services/axios';
import { useDispatch, useSelector } from "react-redux";
import { setSite } from "../redux/actions.js/index.js";

export const useSite = () => {
    
    const token = sessionStorage.getItem('token')

    const dispatch = useDispatch();
    const store = useSelector((store) => store);

    const getSite = async (id) => {
        try {
            return await instance.get(`site/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    dispatch(setSite(response.data))
                    return response
                })
        } catch(err) {
            console.log(err)
        }
    }

    const createSite = async (req) => {
        try {
            return await instance(`site`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: req
            })
        } catch(err) {
            console.log(err)
        }
    }

    const createSiteSignIn = async (req, propToken) => {
        try {
            return await instance(`site-sign-in`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${propToken}`
                },
                data: req
            })
        } catch(err) {
            console.log(err)
        }
    }

    const updateSite = async (req) => {
        try {
            return await instance(`update-site`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: req
            })
        } catch(err) {
            console.log(err)
        }
    }

    return { store, getSite, createSite, createSiteSignIn, updateSite }
}