import instance from '../services/axios';

export const useCoupons = () => {

    const validateCoupon = async (coupon) => {
        try {
            return await instance.get(`coupons/${coupon}`)
        } catch(err) {
            console.error(err)
        }
    }

    return { validateCoupon }
}