import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { emojiEye } from '../../../../assets/icons';
import { useApp, useSelects, useUser } from '../../../../../hooks';
import { Button, SelectModal } from '../../../../components';

export const BasicInformation = () => {

    const { store, updateUser } = useUser();
    const { getIndicativos } = useSelects();
    const { setRefreshFn } = useApp();

    const [buttonDisabled, setButtonDisabled] = useState(true);

    // Name
    const [name, setName] = useState('');
    const [textValidationName, setTextValidationName] = useState('');

    // Number phone
    const [number, setNumber] = useState('');
    const [number2, setNumber2] = useState('');
    const [textValidationNumber, setTextValidationNumber] = useState('');
    const [textValidationNumber2, setTextValidationNumber2] = useState('');
    const [indicatives, setIndicatives] = useState([]);
    const [indicative, setIndicative] = useState('')
    const [showIndicatives, setShowIndicatives] = useState(false)

    const navigate = useNavigate();

    const fetchData = useCallback(() => {
        getIndicativos()
            .then((response) => {
                const resMap = response.data.map(element => ({...element, name: element.INum_Indicativo}))
                const obj = resMap.find(element => element.name === '+57')
                setIndicatives(resMap)
                setIndicative(obj)
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        if(store.user.nombre !== undefined && store.user.nombre !== null) {
            setName(store.user?.nombre)
        } else {
            setName('')
        }

        if(store.user.STelefono) {
            setNumber(store.user?.STelefono)
            setNumber2(store.user?.STelefono)
        } else {
            setNumber('')
            setNumber2('')
        }
    }, [store.user])

    useEffect(() => {
        setRefreshFn(true)
    }, [])

    useEffect(() => {
        const arr = name.split('')
        const arrNumber = number.split('')
        const arrNumber2 = number2.split('')
        const docButton = document.getElementById('button-submit-atomic')
        if(arrNumber.length === 10 && arrNumber2.length === 10 && number === number2) {
            if(arr.length > 4 && arr.length <= 50) {
                const validationTwoName = validateNameComplete(arr)
                if(validationTwoName) {
                    docButton?.classList.remove('button-atomic-disabled')
                    setButtonDisabled(false)
                } else {
                    docButton?.classList.add('button-atomic-disabled')
                    setButtonDisabled(true)
                }
            } else {
                docButton?.classList.add('button-atomic-disabled')
                setButtonDisabled(true)
            }
        } else {
            docButton?.classList.add('button-atomic-disabled')
            setButtonDisabled(true)
        }

        if(arr.length > 4 && arr.length <= 50) {
            const validationTwoName = validateNameComplete(arr)
            if(validationTwoName) {
                setTextValidationName("")
            }
        }

        if(arrNumber.length === 10) {
            setTextValidationNumber("")
        }

        if(arrNumber2.length === 10) {
            setTextValidationNumber2("")
        }

        if(number === number2) {
            setTextValidationNumber2('')
        }

    }, [name, number, number2])

    const validateNameComplete = (arr) => {
        let counter = 0
        return arr.some(element => {
            if(element === ' ') {
                counter++
            }
            if(counter) {
                let char = element.toUpperCase().charCodeAt(0);
                if(char > 64 && char < 91) {
                    return true
                }
            }
        })
    }

    const onBlurName = () => {
        const arr = name.split('')
        if(arr.length > 4 && arr.length < 50) {
            const validate = validateNameComplete(arr)
            if(!validate) {
                setTextValidationName('Recuerda ingresar nombres y apellidos')
            }
        } else {
            setTextValidationName('La cantidad mínima de caracteres son 5')
        }
    }

    const handleChangeName = ({target}) => {
        const arr = target.value.split('')
        if(arr.length === 1 && arr[0] === ' ') {
            
        } else {
            setName(target.value)
        }
    }

    // Number phone
    const onBlurNumber = () => {
        const arr = number.split('')
        if(number !== number2) {
            setTextValidationNumber2("Los números no coinciden")
        }
        if(arr.length !== 10) {
            setTextValidationNumber("Ingresa 10 números")
        }
    }

    const onBlurNumber2 = () => {
        const arr = number2.split('')
        if(number !== number2) {
            setTextValidationNumber2("Los números no coinciden")
        }
        if(arr.length !== 10) {
            setTextValidationNumber2("Ingresa 10 números")
        }
    }

    const handleChangeNumber = ({target}) => {
        const arr = target.value.split('')
        if(arr.length <= 10) {
            setNumber(target.value)
        }
    }

    const handleChangeNumber2 = ({target}) => {
        const arr = target.value.split('')
        if(arr.length <= 10) {
            setNumber2(target.value)
        }
    }

    const handleChangeIndicative = (value) => {
        setIndicative(value)
        setShowIndicatives(false)
    }

    const submit = (event) => {
        event.preventDefault()
        const splitName = name.split(' ')

        updateUser({nombre: name, nombre_favorito: splitName[0], ICod_Tipo_Documento: 1, ICod_Indicativo: indicative.ICodigo, STelefono: number, path: 'site-information/phase-1'})
            .then((response) => {
                if(response.status === 200) {
                    navigate("../site-information/phase-1")
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <>
            <form className="form-basic-information" id="basic-information">
                <img className='emoji' src={emojiEye} alt="" />
                <section className="contain d-flex flex-column justify-content-center align-items-center">
                    <p className="text text-center mt-2 mb-4"><strong>Completa</strong> tus datos básicos</p>

                    {/* Name */}
                    <div className="container-input input-group mb-2">
                        <div className="position-relative">
                            <input type="text" className={`input form-control rounded-3 text-center ${textValidationName !== '' && 'mb-3'}`} maxLength={50} onBlur={onBlurName} onChange={handleChangeName} placeholder="Nombres y apellidos" value={name} autoComplete='off' />
                            { textValidationName !== '' && <p className="message-error">{textValidationName}</p> }
                        </div>
                    </div>

                    {/* Number phone */}
                    <div className={`input-gro position-relative container-phone ${textValidationNumber !== '' ? 'mb-4' : 'mb-2'}`}>
                        <div className="container-input">
                            <input type="number" className={`input form-control rounded-3 text-center`} onBlur={onBlurNumber} onChange={handleChangeNumber} placeholder="Ingresa tu celular" value={number} autoComplete='off' />
                            { textValidationNumber !== '' && <p className="message-error message-error-number-phone">{textValidationNumber}</p> }
                        </div>
                    </div>

                    <div className={`container-input input-group position-relative ${store.user?.ICod_Plan !== 2 ? 'mb-2' : 'mb-4'}`}>
                        <input type="number" className={`input form-control rounded-3 text-center ${textValidationNumber2 !== '' && 'mb-3'}`} onBlur={onBlurNumber2} onChange={handleChangeNumber2} placeholder="Confirma tu celular" value={number2} onPaste={(e) => {e.preventDefault()}} autoComplete='off' />
                        { textValidationNumber2 !== '' && <p className="message-error">{textValidationNumber2}</p> }
                    </div>
                    <Button id="button-submit-atomic" label="Siguiente" onClick={submit} disabled={buttonDisabled} />
                </section>
            </form>
            <div className='container-progress-gratin'>
                <span className='progress-stage'></span>
                <span className='progress-stage progress-active'></span>
                <span className='progress-stage'></span>
                <span className='progress-stage'></span>
                <span className='progress-stage'></span>
            </div>
        </>
    )
}