import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { useUser, useApp } from '../../../../../hooks';
import { emojiHappy, emojiLove } from '../../../../assets/icons';
import { useVerifications } from '../../../../../utils/verifications.utils';
import { MessageInfo } from '../../../../../services/message';
import Recaptcha from 'react-google-invisible-recaptcha';
import ReactPixel from 'react-facebook-pixel';

export const SignIn = ({unregisteredPayment = false, unregistered = false}) => {

    const { createUserWithEmail } = useUser();
    const { clearData, setRefreshFn, trueBack, hideLoading } = useApp();
    const { verificationEmail, onBlurInputDisabledStyle } = useVerifications();
    // const { validateRecaptcha } = useRecaptcha();
    
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [email, setEmail] = useState('')
    const [showAlertInfo, setShowAlertInfo] = useState(false)

    const [textValidationEmail, setTextValidationEmail] = useState('')

    const recaptcha = useRef()

    const navigate = useNavigate();

    useEffect(() => {
        setRefreshFn(false)
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('token')
        clearData()
    }, [])

    const handleChangeEmail = ({ target }) => {
        setTextValidationEmail('')
        const value = target.value.toLowerCase()
        setEmail(value)
        const validationEmail = verificationEmail(value)
        const docButton = document.getElementById('button-submit-atomic')
        if(validationEmail && docButton !== null && docButton !== undefined) {
            setButtonDisabled(false)
            docButton.classList.remove('button-atomic-disabled')
        } else if(docButton !== null && docButton !== undefined) {
            setButtonDisabled(true)
            docButton.classList.add('button-atomic-disabled')
        }
    }

    const recaptchaFn = () => {
        recaptcha.current.callbacks.reset()
        recaptcha.current.callbacks.execute()
            .then(() => {
                sessionStorage.setItem('emailRegister', email)
                hideLoading()
            })
    }

    const submit = () => {
        ReactPixel.init(process.env.REACT_APP_ID_PIXELS_FACEBOOK, {
            autoConfig: false
        });
        ReactPixel.pageView();
        ReactPixel.track('CompleteRegistration');
        const emailRegister = sessionStorage.getItem('emailRegister')
        sessionStorage.removeItem('emailRegister')
        createUserWithEmail({ email: emailRegister, plan: 2, unregisteredPayment: unregisteredPayment, unregistered: unregistered })
            .then((response) => {
                sessionStorage.setItem('email', emailRegister)
                if(response.status === 201) {
                    sessionStorage.setItem('token', response.token)
                    sessionStorage.setItem('id', response.id)
                    setTimeout(() => {
                        navigate("../../basic-information")
                    }, 300)
                } else if(response.status === 230) {
                    sessionStorage.setItem('token', response.token)
                    sessionStorage.setItem('route', response.plan)
                    if(response.path.includes('mode=')) {
                        window.location = `${process.env.REACT_APP_URL_FRONT}${response.path}`
                    } else {
                        navigate(`../../${response.path}`)
                    }
                } else if(response.status === 235 || response.status === 236) {
                    setTextValidationEmail('Este correo ya terminó su registro')
                } else if(response.status === 240) {
                    setShowAlertInfo(true)
                } else if(response.status === 250) {
                    sessionStorage.setItem('token', response.token)
                    trueBack()
                    navigate(`../../plan/phase-1`)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    return (
        <>
            <section className="sign-in">
                <img className='emoji' src={unregistered ? emojiLove : emojiHappy} alt="" />
                <p className="text-center text">{ unregistered ? <><strong>Para continuar</strong> escribe tu correo electrónico registrado</> : <><strong>Regístrate</strong>{' con tu correo electrónico:'}</>}</p>
                <div className="form mb-4">
                    <div className="container-input input-group mb-4">
                        <input id="input-email-register" type="text" className="form-control input" onBlur={() => onBlurInputDisabledStyle("input-email-register")} onChange={handleChangeEmail} placeholder="ejemplo@mail.com" value={email} autoComplete='off' />
                        { textValidationEmail !== '' && <p className="message-error message-error-verification-email">{textValidationEmail}</p> }
                    </div>
                    <Button id={'button-submit-atomic'}  onClick={recaptchaFn} label="Siguiente" disabled={buttonDisabled} />
                    <Recaptcha
                        ref={recaptcha}
                        sitekey="6LcAe6kdAAAAAE9MfRhlYwgHmC4wBj1QaWTB7BTJ"
                        onResolved={submit}
                        badge='bottomleft'
                    />
                </div>
                {
                    !unregistered && (
                        <p className="text-center text-terms-and-conditions" for="text-terminos-y-condiciones">Al hacer clic en siguiente, acepta nuestros <a className="d-inline link-terms-and-conditions" href={`https://energymaster.co/${process.env.REACT_APP_TERMINOS_CONDICIONES}`} download>Términos, Política de datos y Política de cookies.</a></p>
                    )
                }
                {
                    showAlertInfo && (
                        <MessageInfo onClick={() => setShowAlertInfo(false)} title={'Pilas'} message={
                            <>
                                <p>Aún no estás registrado con nosotros, da clic <a href='https://greenbi.co/iniciar/'>aquí</a> para que selecciones tu plan e inicies tu proceso.</p>
                            </>
                        } />
                    )
                }
            </section>
            {
                !unregistered && (
                    <div className='container-progress-gratin'>
                        <span className='progress-stage progress-active'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                    </div>
                )
            }
        </>
    )
}