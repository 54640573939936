import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components';
import { confetti } from '../../../../assets/icons';
import ReactPixel from 'react-facebook-pixel';
import { useUser } from '../../../../../hooks';
import "./congratulations.scss"

export const Congratulations = () => {

    const navigate = useNavigate()
    const { store, getUser } = useUser()

    useEffect(() => {
        getUser()
        const doc = document.getElementById('body')
        doc.classList.add('body-green')
        
        ReactPixel.pageView();
        ReactPixel.track('Purchase');
    }, [])

    const submit = () => {
        const doc = document.getElementById('body')
        doc.classList.remove('body-green')
        navigate(`../../${store.user.path}`)
    }

    return (
        <>
            <section className="congratulations">
                <img className='image-confetti' src={confetti} alt="confetti" />
                <div className='container-titles mb-4'>
                    <p className="title m-0">¡EY QUÉ</p>
                    <p className="title fw-bold m-0">VERRAQUERA!</p>
                </div>
                <p className="text mb-4">Ahora debemos llenar los datos de tu hogar para analizar, comparar y ahorrar.</p>
                <p className="text mb-4">Así tendrás tranquilidad a la hora de pagar y ayudarás al planeta.</p>
                <Button className={'button-normal mb-4 button-submit button-congratulations'} label="Siguiente" onClick={submit} />
            </section>
        </>
    )
}