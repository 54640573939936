import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActiveCampaign, useApp, usePlan, useUser } from '../../../../../hooks';

export const Phase3PL = () => {

    const { getInfoTransactionWompiForId } = usePlan()
    const { showLoading } = useApp()
    const { getUser } = useUser()
    const { paymentDeclined } = useActiveCampaign()

    const navigate = useNavigate();

    const fetchData = useCallback(async() => {
        const user = await getUser()
        let params = (new URL(window.document.location)).searchParams;
        let id = params.get('id');
        let plan = params.get('plan');
        await getInfoTransactionWompiForId(id)
            .then(async({data}) => {
                if(data.data.status === 'APPROVED') {
                    showLoading()
                    // eslint-disable-next-line eqeqeq
                    if(plan == 1) {
                        window.location="https://greenbi.co/iniciar-encoche/"
                    // eslint-disable-next-line eqeqeq
                    } else if(plan == 4) {
                        window.location="https://greenbi.co/iniciar-avion/"
                    } else {
                        window.location="https://greenbi.co/iniciar/"
                    }
                } else { 
                    await paymentDeclined({email: user.data.correo, plan: plan, status: data.data.status}) 
                    navigate(`../../first-steps/phase-1?mode=payment-gateway&status=${data.data.status}&reference=${data.data.reference}`)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    
    return (
        <>
        </>
    )
}