import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { useSite } from '../../../../../hooks';
import { emojiGlasses, emojiPray } from '../../../../assets/icons';
import { Page404, Phase1SI, Phase2SI } from '../components';

export const SiteInformation = ({bdb}) => {

    const { store, getSite } = useSite();

    const { pathname } = useLocation();

    useEffect(() => {
        if(JSON.stringify(store.user) !== '{}') {
            getSite(store.user?.ICodigo)
        }
    }, [store.user])

    return (
        <>
            <img className='emoji' src={pathname.includes('phase-1') ? emojiPray : emojiGlasses} alt="" />
            <section className="site-information">
                <Routes>
                    <Route path="phase-1" element={<Phase1SI />} />
                    <Route path="phase-2" element={<Phase2SI bdb={bdb} />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>

            </section>
            <div className='container-progress'>
                <span className='progress-stage'></span>
                <span className='progress-stage'></span>
                <span className='progress-stage progress-active'></span>
                <span className='progress-stage'></span>
                <span className='progress-stage'></span>
            </div>
        </>
    )
}