import { SET_LOADING, SET_TOKEN, CLEAR_DATA, SET_REFRESH, SET_BACK, SET_ROUTE, SET_VERSION } from "../constants";

const initialState = {
  loading: false,
  token: "",
  refresh: false,
  back: false,
  route: '',
  version: 0
};

const appReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_LOADING:
      return { ...state, loading: action.loading };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case SET_REFRESH:
      return {
        ...state,
        refresh: action.refresh,
      };
    case SET_BACK:
      return {
        ...state,
        back: action.back,
      };
    case SET_VERSION:
      return {
        ...state,
        version: action.version,
      };
    case SET_ROUTE:
      return {
        ...state,
        route: action.route,
      };
    case CLEAR_DATA:
      return initialState;

    default:
      return state;
  }
};

export default appReducer;