import React from 'react';
import "./styles.scss";

export const Radio = ({id, name, label, checked, onClick}) => {

    return (
        <>
            <div class="form-check me-3 ms-3">
                <input class="form-check-input" type="radio" name={name} id={id} checked={checked} onClick={onClick} />
                <label class="form-check-label" for={id}>{label}</label>
            </div>
        </>
    )
}