import React from 'react';
import "./styles.scss";

export const Button = ({ label, className, type, id, onClick = () => {}, disabled, style = {} }) => {

    return (
        <>
            <button type={type} id={id} style={style} className={`button-atomic button-atomic-disabled ${className ?? ''}`} onClick={onClick} disabled={disabled} >{label}</button>
        </>
    )
}