import instance from '../services/axios';
import { BlobServiceClient } from '@azure/storage-blob';
import { useActiveCampaign, useApp, useUser } from '../hooks';
import { UploadFileName } from './file'
import { v4 as uuidv4 } from 'uuid';

export const AzureBlobService = () => {
  
  const { store, addDataPreRegistro } = useUser();
  const { completeInvoices } = useActiveCampaign();
  // const { } = useFi
  const { showLoading } = useApp();

  const token = sessionStorage.getItem('token')

    const getSasToken = () => {
        return instance.get('sas-token', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
    }

    const uploadFiles = async (files) => {
        let token = await getSasToken()
        token = token.replace('/registro', '')
        const blobServiceClient = new BlobServiceClient(token);
        const containerClient = blobServiceClient.getContainerClient("registro");
        
        for(let i = 0; i < files.length; i++) {
          const date = new Date()
          const type = files[i].type.split('/')
          const fileName = 'FacHome_' + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + '_' + date.getHours() + date.getMinutes() + date.getSeconds() + '_' + uuidv4() + '.' + type[1];
          const blockBlobClient = containerClient.getBlockBlobClient(fileName);
          const blockSize = 1 * 1024 * 1024
          const currentSize = files[i].size
          showLoading()
          await blockBlobClient.uploadData(files[i], {blockSize: blockSize, concurrency: 20, onProgress: async(ev) => {
            const percentage = (ev.loadedBytes/currentSize) * 100
            const doc = document.getElementById(`progress-file-${i}`)
            doc.style.width = `${percentage}%`
            if(percentage === 100) {
              await UploadFileName({fileName: fileName})
              if(i === (files.length - 1)) {
                const res = await addDataPreRegistro()
                if(res.status === 201) {
                  sessionStorage.removeItem('token')
                  sessionStorage.removeItem('route')
                  sessionStorage.removeItem('email')
                  showLoading()
                  if(store.user?.ICod_Plan === 1) {
                      window.location="https://greenbi.co/iniciar-encoche/"
                  } else if(store.user?.ICod_Plan === 14) {
                    window.location="https://greenbi.co/iniciar-encoche/"
                  } else if(store.user?.ICod_Plan === 4) {
                      window.location="https://greenbi.co/iniciar-avion/"
                  } else if(store.user?.ICod_Plan === 2) {
                    window.location="https://greenbi.co/iniciar-algratin/"
                  } else {
                    window.location="https://greenbi.co/iniciar/"
                  }
                }
              }
            }
          }})
        }
  }

    return { uploadFiles }
}