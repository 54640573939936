import axios from 'axios';
import { store } from '../redux/config-store';
import { setLoading } from '../redux/actions.js';
import { Navigate } from '../utils'
import { error as messageError } from './message';

let instance = axios.create({
    baseURL: process.env.REACT_APP_URL_API
})

let numLoadings = 0;
const requestHandler = (request) => {
    numLoadings++;
    store.dispatch(setLoading(true));
    return request;
};
const responseHandler = (response) => {
    if ((--numLoadings) === 0) {
        store.dispatch(setLoading(false));
    }

    if (response && response.data) {
        return response.data;
    }

    return response;
};
const errorHandler = (error) => {
    if (!(--numLoadings)) {
        store.dispatch(setLoading(false));
    }
    // messageError('Falló tu conexión, vuelve a intentarlo', 3000);
    <Navigate route={'../../first-steps/phase-1'} />
    return error;
};

instance.interceptors.request.use(request => requestHandler(request))
instance.interceptors.response.use(response => responseHandler(response), error => errorHandler(error));

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export default instance;