import { CLEAR_USER, SET_USER } from "../constants";

const initialState = {};

const userReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_USER:
      return action.user;
    case CLEAR_USER:
      return initialState;

    default:
      return state;
  }
};

export default userReducer;