import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Icon = ({ icon, className }) => {

    return(
        <>
            <FontAwesomeIcon className={className} icon={icon} />
        </>
    )
}