/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button, Coupon, Plan, SelectModal } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { useUser, useApp, useSelects, useSite, usePlan, useActiveCampaign } from '../../../../../hooks';
import { emojiHappyEyesOpen } from '../../../../assets/icons';
import { useVerifications } from '../../../../../utils/verifications.utils';
import { error, MessageInfo } from '../../../../../services/message';
import Recaptcha from 'react-google-invisible-recaptcha';
import ReactPixel from 'react-facebook-pixel';
import { cloneDeep } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export const SignInPayment = ({unregisteredPayment = false, unregistered = false, bdb = false}) => {

    const messageNotRegister = <><p>Aún no estás registrado con nosotros, da clic <a href='https://greenbi.co/iniciar/'>aqui</a> para que selecciones tu plan e inicies tu proceso.</p></>
    const messageCodeDiscount = <><p>El cupón ingresado no es válido</p></>

    const { store, createUserWithEmailPayment, updateUserPayment, getUserWithReference, getUserWithEmail } = useUser();
    const { createSiteSignIn } = useSite();
    const { clearData, setRefreshFn, hideLoading, showLoading } = useApp();
    const { verificationEmail } = useVerifications();
    const { getTypeDocuments, getIndicativos, getCountries } = useSelects();
    const { getPlan, getPlanUpgrade, upgrade } = usePlan();
    const { completeCP } = useActiveCampaign();

    // Name
    const [name, setName] = useState('');
    const [textValidationName, setTextValidationName] = useState('');

    // Number phone
    const [number, setNumber] = useState('');
    const [textValidationNumber, setTextValidationNumber] = useState('');
    const [indicative, setIndicative] = useState({})
    
    // Cedula
    const [doc, setDoc] = useState('');
    const [textValidationDoc, setTextValidationDoc] = useState('');
    const [typesDocument, setTypesDocument] = useState([]);
    const [typeDocument, setTypeDocument] = useState('')
    const [showTypeDocument, setShowTypeDocument] = useState(false);

    // Plam
    const [plan, setPlan] = useState({})
    const [price, setPrice] = useState('')
    const [priceDiscount, setPriceDiscount] = useState('')
    
    // Address
    const [address, setAddress] = useState('')
    const [textValidationAddress, setTextValidationAddress] = useState('')
    const [ubicationsOriginal, setUbicationsOriginal] = useState([])
    const [ubications, setUbications] = useState([])
    const [ubication, setUbication] = useState({})
    const [showUbications, setShowUbications] = useState(false);
    
    // Payment
    const [paymentDeclined, setPaymentDeclined] = useState(false)
    const [stateUnregisteredPayment, setStateUnregisteredPayment] = useState(false)
    const [upgrade2, setUpgrade2] = useState(false)

    // Coupon
    const [code, setCode] = useState('');
    const [business, setBusiness] = useState('');
    const [discount, setDiscount] = useState(0);
    const [priceWithCoupon, setPriceWithCoupon] = useState('');

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [email, setEmail] = useState('')
    const [showAlertInfo, setShowAlertInfo] = useState(false)

    const [textValidationEmail, setTextValidationEmail] = useState('')

    const [messageAlert, setMessageAlert] = useState(<></>)

    const recaptcha = useRef()

    const navigate = useNavigate();

    const fetchDataPaymentRejected = useCallback(async() => {
        let params = (new URL(window.document.location)).searchParams;
        let reference = params.get('reference');
        let status = params.get('status');
        let paramEmail = params.get('email');
        if(status && reference) {
            await getUserWithReference(reference)
            setPaymentDeclined(true)
            setMessageAlert(messageNotRegister)
            error(`Transaccion ${status === 'DECLINED' ? 'rechazada' : status === 'VOIDED' ? 'anulada' : 'error'}`, 3000)
        } else if(paramEmail) {
            await getUserWithEmail(paramEmail)
        }
    }, [])

    useEffect(() => {
        fetchDataPaymentRejected()
    }, [fetchDataPaymentRejected])

    useEffect(() => {

        if(JSON.stringify(store.user) !== '{}') {
            if(store.user.nombre) setName(store.user.nombre)
            if(store.user.correo) setEmail(store.user.correo)
            if(store.user.STelefono) setNumber(store.user.STelefono)
            if(store.user.SCedula) setDoc(store.user.SCedula)
        } else {
            setName('')
            setNumber('')
            setDoc('')
        }
        
        if(JSON.stringify(store.site) !== '{}' && ubicationsOriginal.length > 0 && store.user.SReferenciaPago !== null) {
            const objCity = ubicationsOriginal.find(element => element.ICodigo === store.site.ICod_Ciudad)
            setUbication(objCity)
            setAddress(store.site.SDireccion)
        } else {
            setUbication({})
            setAddress('')
        }

    }, [store.user, store.site, ubicationsOriginal, upgrade2])

    useEffect(() => {
        setRefreshFn(false)
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('token')
        clearData()
    }, [])

    const fetchData = useCallback(() => {
        getTypeDocuments()
            .then((response) => {
                const resArray = response.data.map(element => ({...element, name: `${element.Descripcion_Corta} - ${element.SDescripcion}`}))
                const obj = resArray.find(element => element.SDescripcion === 'Cedula')
                setTypesDocument(resArray)
                setTypeDocument(obj)
            })
            .catch((err) => {
                console.log(err)
            })

        getIndicativos()
            .then((response) => {
                const obj = response.data.find(element => element.INum_Indicativo === '+57')
                setIndicative(obj)
            })
            .catch((err) => {
                console.log(err)
            })

        getCountries()
            .then((response) => {
                const resCities = response.data.cities.map(element => {
                    const departament = response.data.departaments.find(element2 => element2.ICodigo === element.ICod_departamento)
                    return {ICodigo: element.ICodigo,name: `${element.SCiudad.toLowerCase()} - ${departament.SDepartamento.toLowerCase()}`, value: element.ICodigo, idDepartament: element.ICod_departamento}
                })
                setUbicationsOriginal(cloneDeep(resCities))
            })
            .catch(err => {
                console.error(err)
            })
        
        // Wompi
        document.getElementById('button-submit-atomic-payment')?.classList.remove('button-atomic-disabled')
        const script = document.createElement("script");
        script.src = "https://checkout.wompi.co/widget.js";
        script.async = true;
        document.body.appendChild(script);

        const planSession = sessionStorage.getItem('route')
        getPlan(planSession === 'plan-coche' ? 1 : planSession === 'plan-avion' ? 4 : 14)
            .then(({data}) => {
                setPlan(data)
                const discountR = data.SValor * .6
                const pricePoints = pointsToThousands(data?.SValor)
                const priceDiscountPoints = pointsToThousands(discountR)
                setPrice(pricePoints)
                setPriceDiscount(priceDiscountPoints)
                setPriceWithCoupon(priceDiscountPoints)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        
        const arr = name.split('')
        const arrNumber = number.split('')
        const arrDoc = doc.split('')
        const validate = validateNameComplete(arr)
        const validationEmail = verificationEmail(email)
        const validateAddress = validationAddress(address)
        const docButton = document.getElementById('button-submit-atomic')

        if(arr.length > 4 && arr.length < 50 && arrNumber.length === 10 && arrDoc.length > 4 && validate && validationEmail && JSON.stringify(ubication) !== '{}' && address.length >= 5 && validateAddress && docButton !== null && docButton !== undefined) {
            setButtonDisabled(false)
            docButton.classList.remove('button-atomic-disabled')
        } else {
            setButtonDisabled(true)
            docButton.classList.add('button-atomic-disabled')
        }

        // Name
        if(arr.length > 4 && arr.length < 50) {
            if(validate) {
                setTextValidationName('')
            }
        }

        // Email
        if(validationEmail) {
            setTextValidationEmail("")
        }

        // number
        if(arrNumber.length === 10) {
            setTextValidationNumber("")
        }

        // cedula
        if(arrDoc.length > 4) {
            setTextValidationDoc("")
        }

        // Address
        const resValidate = validationAddress(address)
        if(address.length >= 5 && resValidate) {
            setTextValidationAddress('')
        } else if(resValidate) {
            setTextValidationAddress('')
        } else if(address.length >= 5) {
            setTextValidationAddress('')
        }

    }, [name, email, number, doc, ubication, address])

    // Coupon
    useEffect(() => {
        let amount = priceDiscount.replaceAll('.','')
        amount = parseInt(amount.replace('$', ''))
        amount *= ((100 - discount) / 100)
        amount = pointsToThousands(amount)
        setPriceWithCoupon(amount)
    }, [discount])

    // Name
    const validateNameComplete = (arr) => {
        let counter = 0
        return arr.some(element => {
            if(element === ' ') {
                counter++
            }
            if(counter) {
                let char = element.toUpperCase().charCodeAt(0);
                if(char > 64 && char < 91) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        })
    }

    const onBlurName = () => {
        const arr = name.split('')
        if(arr.length > 4 && arr.length < 50) {
            const validate = validateNameComplete(arr)
            if(!validate) {
                setTextValidationName('Recuerda ingresar nombres y apellidos')
            }
        } else {
            setTextValidationName('La cantidad mínima de caracteres son 5')
        }
    }

    const handleChangeName = ({target}) => {
        const arr = target.value.split('')
        if(arr.length === 1 && arr[0] === ' ') {
            
        } else {
            setName(target.value)
        }
    }

    // Email
    const handleChangeEmail = ({ target }) => {
        const value = target.value.toLowerCase()
        setEmail(value)
    }

    const onBlurEmail = async() => {
        const validationEmail = verificationEmail(email)
        if(!validationEmail) {
            setTextValidationEmail("Escribe correctamente tu correo")
        } else {
            const resUser = await getUserWithEmail(email)
            console.log(resUser)
            if(resUser.status === 200) {
                if(resUser.data.user.ICod_Plan === 2) {
                    setStateUnregisteredPayment(resUser.data.user.Finalizado)
                    setUpgrade2(true)
                }
            }
        }
    }

    const recaptchaFn = () => {
        recaptcha.current.callbacks.reset()
        recaptcha.current.callbacks.execute()
            .then(() => {
                const objRegister = {
                    name: name, 
                    email: email,
                    indicative: indicative,
                    number: number, 
                    typeDocument: typeDocument,
                    doc: doc,
                    ubication: ubication,
                    address: address,
                    plan: plan,
                    priceDiscount: priceWithCoupon,
                    reference:  store.user.SReferenciaPago,
                    stateUnregisteredPayment:  stateUnregisteredPayment,
                    upgrade:  upgrade2,
                    code:  code,
                    path: store.user?.path
                }
                sessionStorage.setItem('dataRegister', JSON.stringify(objRegister))
                hideLoading()
            })
    }

    // Number phone
    const onBlurNumber = () => {
        const arr = number.split('')
        if(arr.length !== 10) {
            setTextValidationNumber("Ingresa 10 números")
        }
    }

    const handleChangeNumber = ({target}) => {
        const arr = target.value.split('')
        if(arr.length <= 10) {
            setNumber(target.value)
        }
    }

    // Cedula
    const onBlurDoc = () => {
        const arr = doc.split('')
        if(arr.length <= 4) {
            setTextValidationDoc("La cantidad mínima de caracteres son 5")
        }
    }

    const handleChangeDoc = ({target}) => {
        setDoc(target.value)
    }

    const handleChangeTypeDoc = (value) => {
        setTypeDocument(value)
        setShowTypeDocument(false)
    }

    // Address
    useEffect(() => {
        setUbications(ubicationsOriginal)
    }, [showUbications])

    const handleChangeUbication = (value) => {
        setUbication(value)
        setShowUbications(false)
    }

    const handleChangeAddress = ({target}) => {
        setAddress(target.value)
    }

    const onBlurAddress = () => {
        const resValidate = validationAddress(address)
        if(address.length >= 5) {
            if(!resValidate) {
                setTextValidationAddress('Debe contener al menos un número')
            } else {
                setTextValidationAddress('')
            }
        } else {
            setTextValidationAddress('La cantidad mínima de caracteres son 5')
        }
    }

    const validationAddress = (value) => {
        const valueSplit = value.split('')
        return valueSplit.some(element => !isNaN(element) && element !== ' ')
    }

    // Payment
    const payment = async(reference, data, propToken) => {
        const amount = data.priceDiscount.replaceAll('.','')
        let checkout = new window.WidgetCheckout({
            currency: 'COP',
            amountInCents: (bdb ? parseInt(amount.replace('$', '')) : data.plan.SValor) * 100,
            reference:  reference,
            publicKey: process.env.REACT_APP_PUBLIC_KEY_WOMPI,
            redirectUrl: `${process.env.REACT_APP_URL_FRONT}plan/phase-${data.stateUnregisteredPayment ? ('3?plan='+data.plan.ICodigo) : 2}`,
            customerData: {
                email: data.email,
                fullName: data.name,
                phoneNumber: data.number,
                phoneNumberPrefix: '+57'
            }
        })
        ReactPixel.init(process.env.REACT_APP_ID_PIXELS_FACEBOOK, {
            autoConfig: false
        });
        ReactPixel.pageView();
        ReactPixel.track('InitiateCheckout');
        if(paymentDeclined) {
            if(data.upgrade) await getPlanUpgrade(data.plan.ICodigo, propToken)
            checkout.open( async ( result ) => {
                //Guardar en la base de datos
                let transaction = result.transaction
                if(transaction.status === "APPROVED") {
                    await completeCP(propToken)
                    if(data.stateUnregisteredPayment) {
                        showLoading()
                        if(data.plan.ICodigo === 1) {
                            window.location="https://greenbi.co/iniciar-encoche/"
                        } else {
                            window.location="https://greenbi.co/iniciar-avion/"
                        }
                    } else {
                        navigate("../../congratulations")
                    }
                } else {
                    setPaymentDeclined(true)
                    setMessageAlert(messageNotRegister)
                    error(`Transaccion ${transaction.status === 'DECLINED' ? 'rechazada' : transaction.status === 'VOIDED' ? 'anulada' : 'error'}`, 3000)
                    console.error(`Transaccion ${transaction.status}`)
                }
            })
        } else {
            if(data.upgrade) await getPlanUpgrade(data.plan.ICodigo, propToken)
            updateUserPayment({SReferenciaPago: reference}, propToken)
                .then(() => {
                    checkout.open( async ( result ) => {
                        //Guardar en la base de datos
                        let transaction = result.transaction
                        if(transaction.status === "APPROVED") {
                            await completeCP(propToken)
                            if(data.stateUnregisteredPayment) {
                                showLoading()
                                if(data.plan.ICodigo === 1) {
                                    window.location="https://greenbi.co/iniciar-encoche/"
                                } else {
                                    window.location="https://greenbi.co/iniciar-avion/"
                                }
                            } else {
                                navigate("../../congratulations")
                            }
                        } else {
                            setPaymentDeclined(true)
                            setMessageAlert(messageNotRegister)
                            error(`Transaccion ${transaction.status === 'DECLINED' ? 'rechazada' : transaction.status === 'VOIDED' ? 'anulada' : 'error'}`, 3000)
                            console.error(`Transaccion ${transaction.status}`)
                        }
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const pointsToThousands = (number) => {
        let numberWithPoints = []
        const arrayNumber = JSON.stringify(number).split('')
        let counter = 0
        for(let i = (arrayNumber.length - 1); i >= 0; i--) {
            if(counter < 3) {
                numberWithPoints.unshift(arrayNumber[i])
                counter++
            } else {
                numberWithPoints.unshift(arrayNumber[i], '.')
                counter = 0
            }
        }
        return `$${numberWithPoints.toString().replaceAll(',', '')}`
    }

    const submit = () => {
        const dataRegister = sessionStorage.getItem('dataRegister')
        const dataRegisterParse = JSON.parse(dataRegister)
        const splitName = dataRegisterParse.name.split(' ')
        sessionStorage.removeItem('dataRegister')
        let amount = dataRegisterParse.priceDiscount.replaceAll('.','')
        amount = parseInt(amount.replace('$', ''))
        createUserWithEmailPayment({ nombre: dataRegisterParse.name, nombre_favorito: splitName[0], email: dataRegisterParse.email, ICod_Indicativo: dataRegisterParse.indicative.ICodigo, STelefono: dataRegisterParse.number, ICod_Tipo_Documento: dataRegisterParse.typeDocument.ICodigo, SCedula: dataRegisterParse.doc, plan: dataRegisterParse.plan.ICodigo, unregisteredPayment: unregisteredPayment, unregisteredPayment2: dataRegisterParse.upgrade, unregistered: unregistered, bdb: bdb, SCupon_Descuento: dataRegisterParse.code })
            .then((response) => {
                if(response.status === 201) {
                    createSiteSignIn({ ICod_Ciudad: dataRegisterParse.ubication.ICodigo, SDireccion: dataRegisterParse.address}, response.token)
                        .then(async(resSite) => {
                            if(resSite.status === 201) {
                                sessionStorage.setItem('email', dataRegisterParse.email)
                                sessionStorage.setItem('token', response.token)
                                sessionStorage.setItem('id', response.id) 
                                if(bdb) {
                                    await updateUserPayment({path: 'site-information/phase-1'}, response.token)
                                    navigate("../../site-information/phase-1")
                                } else {
                                    if(amount > 0) {
                                        const date = new Date()
                                        const string = date.toLocaleString()
                                        const reference = btoa(string)
                                        payment(reference, dataRegisterParse, response.token)
                                    } else {
                                        if(dataRegisterParse.upgrade) {
                                            await upgrade(dataRegisterParse.plan.ICodigo, null, response.token)
                                        } else {
                                            await upgrade(dataRegisterParse.plan.ICodigo, 'site-information/phase-1', response.token)
                                        }
                                        if(dataRegisterParse.stateUnregisteredPayment) {
                                            showLoading()
                                            if(dataRegisterParse.plan.ICodigo === 1) {
                                                window.location="https://greenbi.co/iniciar-encoche/"
                                            } else {
                                                window.location="https://greenbi.co/iniciar-avion/"
                                            }
                                        } else {
                                            navigate("../../congratulations")
                                        }
                                    }
                                }
                            } else {

                            }
                        })
                        .catch((err) => {
                            console.error(err)
                        })
                } else if(response.status === 280) {
                    createSiteSignIn({ ICod_Ciudad: dataRegisterParse.ubication.ICodigo, SDireccion: dataRegisterParse.address}, response.token)
                        .then(async(resSite) => {
                            if(resSite.status === 280) {
                                sessionStorage.setItem('email', dataRegisterParse.email)
                                sessionStorage.setItem('token', response.token)
                                sessionStorage.setItem('id', response.id) 
                                if(bdb) {
                                    await updateUserPayment({path: 'site-information/phase-1'}, response.token)
                                    navigate("../../site-information/phase-1")
                                } else {
                                    if(amount > 0) {
                                        const date = new Date()
                                        const string = date.toLocaleString()
                                        const reference = btoa(string)
                                        payment(response.data.SReferenciaPago === null ? reference : response.data.SReferenciaPago, dataRegisterParse, response.token)
                                    } else {
                                        if(dataRegisterParse.upgrade) {
                                            await upgrade(dataRegisterParse.plan.ICodigo, null, response.token)
                                        } else {
                                            await upgrade(dataRegisterParse.plan.ICodigo, 'site-information/phase-1', response.token)
                                        }
                                        if(dataRegisterParse.stateUnregisteredPayment) {
                                            showLoading()
                                            if(dataRegisterParse.plan.ICodigo === 1) {
                                                window.location="https://greenbi.co/iniciar-encoche/"
                                            } else {
                                                window.location="https://greenbi.co/iniciar-avion/"
                                            }
                                        } else {
                                            navigate("../../congratulations")
                                        }
                                    }
                                }
                            } else if(resSite.status === 201) {
                                sessionStorage.setItem('email', dataRegisterParse.email)
                                sessionStorage.setItem('token', response.token)
                                sessionStorage.setItem('id', response.id) 
                                if(bdb) {
                                    await updateUserPayment({path: 'site-information/phase-1'}, response.token)
                                    navigate("../../site-information/phase-1")
                                } else {
                                    if(amount > 0) {
                                        const date = new Date()
                                        const string = date.toLocaleString()
                                        const reference = btoa(string)
                                        payment(response.data.SReferenciaPago === null ? reference : response.data.SReferenciaPago, dataRegisterParse, response.token)
                                    } else {
                                        if(dataRegisterParse.upgrade) {
                                            await upgrade(dataRegisterParse.plan.ICodigo, null, response.token)
                                        } else {
                                            await upgrade(dataRegisterParse.plan.ICodigo, 'site-information/phase-1', response.token)
                                        }
                                        if(dataRegisterParse.stateUnregisteredPayment) {
                                            showLoading()
                                            if(dataRegisterParse.plan.ICodigo === 1) {
                                                window.location="https://greenbi.co/iniciar-encoche/"
                                            } else {
                                                window.location="https://greenbi.co/iniciar-avion/"
                                            }
                                        } else {
                                            navigate("../../congratulations")
                                        }
                                    }
                                }
                            }
                        })
                        .catch((err) => {
                            console.error(err)
                        })
                } else if(response.status === 235 || response.status === 236) {
                    setTextValidationEmail('Este correo ya terminó su registro')
                } else if(response.status === 242) {
                    sessionStorage.setItem('route', 'plan-gratin')
                    sessionStorage.setItem('token', response.token)
                    navigate(`../../${response.path}`)
                } else if(response.status === 241) {
                    sessionStorage.setItem('route', `plan-${response.plan === 1 ? 'coche' : 'avion'}`)
                    sessionStorage.setItem('token', response.token)
                    if(response.path.includes('mode=')) {
                        window.location = `${process.env.REACT_APP_URL_FRONT}${response.path}`
                    } else {
                        navigate(`../../${response.path}`)
                    }
                } else if(response.status === 240) {
                    setMessageAlert(messageNotRegister)
                    setShowAlertInfo(true)
                } else if(response.status === 270) {
                    setTextValidationDoc('Este documento ya cuenta con un registro')
                } else if(response.status === 265) {
                    setMessageAlert(messageCodeDiscount)
                    error(`Codigo vencido`, 3000)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    return (
        <>
            <section className="sign-in-payment">
                <img className='emoji' src={emojiHappyEyesOpen} alt="" />
                <div className='container-title mb-2'>
                    <p className="text-center text m-0">{ bdb ? 'BENEFICIOS PLAN' : 'RESUMEN DE TU COMPRA'} </p>
                    {
                        bdb ? (
                            <strong className='text-center text text-uppercase'>EN COCHE</strong>
                        ) : (
                            <strong className='text-center text text-uppercase'>{plan.SNombre}</strong>
                        )
                    }
                </div>
                <div className='container-title'>
                    {
                        !bdb && (
                            <>
                                <p className='price m-0'>Precio normal <span>{price}</span></p>
                            </>
                        )
                    }
                    <p className='description-plan m-0'>12 reportes uno cada mes.</p>
                    {
                        bdb && JSON.stringify(plan) !== '{}' && <Plan data={plan} bdb={bdb} />
                    }
                </div>
                <div className='border mb-2 mt-2'></div>
                <p className='title-sign-in-payment mb-1'>{ bdb ? 'Datos para realizar tu registro' : 'Datos para generar tu factura'}</p>
                <div className="form">

                    {/* Name */}
                    <div className="container-input w-100 input-group mb-2">
                        <div className="position-relative w-100">
                            <input type="text" className={`input form-control rounded-5 text-center ${textValidationName !== '' && 'mb-3'}`} maxLength={50} onBlur={onBlurName} onChange={handleChangeName} placeholder="Nombres y apellidos" value={name} autoComplete='off' />
                            { textValidationName !== '' && <p className="message-error">{textValidationName}</p> }
                        </div>
                    </div>

                    {/* email */}
                    <div className={`container-input input-group ${textValidationEmail !== '' ? 'mb-4' : 'mb-2'}`}>
                        <input id="input-email-register" type="text" className="form-control input" onBlur={() => onBlurEmail()} onChange={handleChangeEmail} placeholder="ejemplo@mail.com" value={email} autoComplete='off' />
                        { textValidationEmail !== '' && <p className="message-error message-error-verification-email">{textValidationEmail}</p> }
                    </div>

                    {/* Number phone */}
                    <div class={`input-gro position-relative container-phone rounded-5 ${textValidationNumber !== '' ? 'mb-4' : 'mb-2'}`}>
                        <div className="container-input">
                            <input type="number" className={`input form-control rounded-5 text-center`} onBlur={onBlurNumber} onChange={handleChangeNumber} placeholder="Ingresa tu número de celular" value={number} autoComplete='off' />
                            { textValidationNumber !== '' && <p className="message-error message-error-number-phone">{textValidationNumber}</p> }
                        </div>
                    </div>

                    {/* Cedula */}
                    <div className={`container-cedula position-relative ${textValidationDoc !== '' ? 'mb-4' : 'mb-2'}`}> 
                        <div className='w-100 position-relative'>
                            <Button className={"cancel-disabled-button w-100 button-select-modal"} onClick={() => setShowTypeDocument(true) } label={typeDocument.Descripcion_Corta} />
                            <FontAwesomeIcon className='icon-down' icon={faAngleDown} onClick={() => setShowTypeDocument(true) } />
                        </div>
                        <div className='container-select-modal'>
                            { showTypeDocument && <SelectModal data={typesDocument} className={'bottom-0'} selected={handleChangeTypeDoc} hideModal={() => setShowTypeDocument(false)} withoutCapitalize={true} /> }
                        </div>
                        <div className="container-input input-group">
                            <input type="text" data-bs-toggle="tooltip" data-bs-placement="top" title="Ingresa tu número de documento" className="input form-control rounded-5 text-center" maxLength={20} onBlur={onBlurDoc} onChange={handleChangeDoc} placeholder="Número de documento" value={doc} autoComplete='off' />
                        </div>
                        { textValidationDoc !== '' && <p className="message-error mt-2">{textValidationDoc}</p> }
                    </div>

                    {/* Address */}
                    <div className='container-address mb-2'>
                        <div className='w-100 position-relative'>
                            <Button className={"cancel-disabled-button w-100 button-select-modal text-capitalize"} onClick={() => setShowUbications(true) } label={JSON.stringify(ubication) !== '{}' ? ubication.name : 'Ciudad'} />
                            <FontAwesomeIcon className='icon-down' icon={faAngleDown} onClick={() => setShowUbications(true) } />
                        </div>
                        <div className='container-select-modal'>
                            { showUbications && <SelectModal data={ubications} dataOriginal={ubicationsOriginal} setData={setUbications} className={'select-modal-cities'} classNameOption={'text-capitalize'} selected={handleChangeUbication} hideModal={() => setShowUbications(false)} searchFilter={true} /> }
                        </div>
                    </div>
                    <div class={`input-gro position-relative container-address rounded-5 ${textValidationAddress !== '' ? 'mb-4' : 'mb-2'}`}>
                        <div className="container-input">
                            <input type="text" className={`input form-control rounded-5 text-center`} onBlur={onBlurAddress} onChange={handleChangeAddress} placeholder="Dirección" value={address} maxLength={50} autoComplete='off' />
                            { textValidationAddress !== '' && <p className="message-error message-error-address">{textValidationAddress}</p> }
                        </div>
                    </div>

                    {
                        !bdb && (
                            <Coupon 
                                code={code}
                                setCode={setCode}
                                business={business}
                                setBusiness={setBusiness}
                                discount={discount}
                                setDiscount={setDiscount}
                                price={priceWithCoupon}
                                setPrice={setPriceWithCoupon}
                                priceInitial={priceDiscount}
                            />
                        )
                    }

                    <Button id={'button-submit-atomic'} style={{marginTop: '12px'}} onClick={recaptchaFn} label={bdb || discount === 100 ? 'Siguiente' : 'Pagar'} disabled={buttonDisabled} />
                    <Recaptcha
                        ref={recaptcha}
                        sitekey="6LcAe6kdAAAAAE9MfRhlYwgHmC4wBj1QaWTB7BTJ"
                        onResolved={submit}
                        badge='bottomleft'
                    />
                </div>
                <p className="text-center mt-2 text-terms-and-conditions" for="text-terminos-y-condiciones">Al hacer clic en siguiente, acepta nuestros <a className="d-inline link-terms-and-conditions" href={`https://energymaster.co/${process.env.REACT_APP_TERMINOS_CONDICIONES}`} download>Términos, Política de datos y Política de cookies.</a></p>
                {
                    showAlertInfo && (
                        <MessageInfo onClick={() => setShowAlertInfo(false)} title={'Pilas'} message={messageAlert} />
                    )
                }
            </section>
        </>
    )
}