import React, { useEffect } from 'react';
import { confetti } from '../../../../assets/icons';
import "./congratulations.scss"
import { useUser } from '../../../../../hooks';

export const CongratulationsSura = () => {

    const { getUser } = useUser()

    useEffect(() => {
        getUser()
        const doc = document.getElementById('body')
        doc.classList.add('body-green')
    }, [])

    return (
        <>
            <section className="congratulations">
                <img className='image-confetti' src={confetti} alt="confetti" />
                <div className='container-titles mb-4'>
                    <p className="title fw-bold m-0">¡FELICITACIONES!</p>
                </div>
                <p className="text mb-4">Tu registro se ha completado exitosamente</p>
            </section>
        </>
    )
}