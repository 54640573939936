export const SET_USER = "SET_USER";
export const CLEAR_DATA = "CLEAR_DATA";
export const SET_LOADING = "SET_LOADING";
export const SET_TOKEN = "SET_TOKEN";
export const SET_REFRESH = "SET_REFRESH";
export const SET_SITE = "SET_SITE";
export const SET_BACK = "SET_BACK";
export const CLEAR_SITE = "CLEAR_SITE";
export const CLEAR_USER = "CLEAR_USER";
export const SET_ROUTE = "SET_ROUTE";
export const SET_VERSION = "SET_VERSION";