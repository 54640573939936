import React, { useEffect, useState } from 'react';
import { emojiParty } from '../../../../assets/icons';
import { Button } from '../../../../components';
import { useApp } from '../../../../../hooks';
import { AzureBlobService } from '../../../../../services/azure-blob.services';
import { error as messageError } from '../../../../../services/message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const UploadFiles = ({bdb}) => {

    const { store, setRefreshFn, showLoading } = useApp();

    const { uploadFiles } = AzureBlobService();

    const [dropzoneDisabled, setDropzoneDisabled] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [buttonDisabled2, setButtonDisabled2] = useState(false)
    const [files, setFiles] = useState([]);
    const [bgActive, setBgActive] = useState(false)

    useEffect(() => {
        setRefreshFn(true)
    }, [])

    useEffect(() => {
        const docButton = document.getElementById('button-submit-atomic')
        const docButton2 = document.getElementById('button-submit-atomic-2')
        if(files.length === 5) {
            setDropzoneDisabled(true)
        } else {
            setDropzoneDisabled(false)
        }
        if(files.length > 0) {
            docButton?.classList.remove('button-atomic-disabled')
            docButton2?.classList.remove('border-button')
            setButtonDisabled(false)
            setButtonDisabled2(true)
        } else {
            docButton?.classList.add('button-atomic-disabled')
            docButton2?.classList.add('border-button')
            setButtonDisabled(true)
            setButtonDisabled2(false)
        } 
    }, [files])

    const handleChange = ({target}) => {
        const maxSize = 20971520
        const fileList = [...target.files, ...files]
        let counter = 0
        const filesArray = fileList.filter((element, index) => {
            console.log(element.size, element.size > maxSize)
            if(element.size > maxSize) {
                counter++
            }
            return index < 5 && element.size < maxSize
        })
        if(counter === 1) {
            messageError('El tamaño de algun archivo adjunto supera el límite permitido', 3000);
        } else if(counter > 1) {
            messageError('El tamaño de algunos archivos adjuntos superan el límite permitido', 3000);
        }
        setFiles(filesArray)
        let inputFile = document.getElementById('multiple-invoices')
        inputFile.value = ''
    }

    const deleteItem = (index) => {
        let copyFiles = [...files]
        copyFiles.splice(index, 1)
        setFiles(copyFiles)
    }

    const onSubmit = async() => {
        try {
            setBgActive(true)
            await uploadFiles(files)
           
        } catch(err) {
            console.error(err)
        }
    }

    const openInputfile = () => {
        let doc = document.getElementById('multiple-invoices')
        doc.click()
    }

    const finallyWithoutInvoice = () => {
        showLoading()
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('route')
        sessionStorage.removeItem('email')
        window.location="https://greenbi.co/registro-completado/"
    }

    return (
        <>
            <div className='container-emoji'><img className='emoji-upload-files' src={emojiParty} alt="" /></div>
            <section className="upload-files position-relativ mt-2">
                <h5 className="title">¡Tus facturas de servicios públicos <strong>son indispensables!</strong></h5>
                <p className="text">Nosotros las revisamos con lupa y cuando encontremos algo raro, te decimos qué hacer (o lo hacemos por ti) en el reporte que recibes cada mes en tu teléfono celular o correo registrado.</p>
                <div className='border-upload-files'></div>
                {
                    files.map((element, index) => 
                        <div className='container-info-file'>
                            <FontAwesomeIcon className='button-delete-file' icon={faTimes} onClick={() => deleteItem(index)}/>
                            <span className='file-name'>{element.name}</span>
                            <span className='file-size'>{parseInt(element.size / 1024) < 1000 ?  `${parseInt(element.size / 1024)} kb` : `${parseInt(element.size / 1024 / 1024)} mb`}</span>
                            <div className={`container-progress-file ${bgActive && 'container-progress-file--bg'}`}>
                                <div id={`progress-file-${index}`} className='progress-file'></div>
                            </div>
                            <div className='border-file'></div>
                        </div>
                    )
                }
                <input hidden id="multiple-invoices" type="file" multiple accept='.pdf, .jpg, .png, .heic' onChange={handleChange} disabled={dropzoneDisabled} />
                <Button className={`cancel-disabled-button border-button w-100 mb-1 button-select-modal ${dropzoneDisabled ? 'button-especial' : ''}`} onClick={openInputfile} label={'Toca aquí para cargar facturas'} disabled={dropzoneDisabled} />
                <p className="text fst-italic">Max. hasta 5 archivos (foto o PDF)</p>
                <Button id="button-submit-atomic-2" className={`cancel-disabled-button border-button mb-1 ${buttonDisabled2 ? 'button-especial' : ''}`} disabled={buttonDisabled2} onClick={finallyWithoutInvoice} label="Cargar mis facturas después" />
                <p className="text fst-italic">(Tus datos hasta aquí han quedado guardados)</p>
                <Button id="button-submit-atomic" className="mb-4" label="Finalizar" disabled={buttonDisabled} onClick={onSubmit} />
            </section>
            {
                store.user?.ICod_Plan === 2 ? (
                    <div className='container-progress-gratin'>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage progress-active'></span>
                    </div>
                ) : (  
                    <div className='container-progress-payment'>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage progress-active'></span>
                    </div>
                )
            }
        </>
    )
}