import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActiveCampaign, useApp, useSiigo, useSite, useUser } from '../../../../../hooks';
import { emojiGlasses } from '../../../../assets/icons';
import { Button, Radio } from '../../../../components';

export const Phase2SI = ({bdb}) => {

    const { store, updateUser } = useUser();
    const { updateSite } = useSite();
    const { setRefreshFn, trueBack } = useApp();
    const { billingSiigo } = useSiigo();
    const { completeInfoSite } = useActiveCampaign();

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [comercialActivity, setComercialActivity] = useState('')
    const [showComercialActivity, setShowComercialActivity] = useState(false)

    const [radio, setRadio] = useState({primary: false, secondary: false, third: false})

    const navigate = useNavigate()

    useEffect(() => {
        setRefreshFn(true)
    }, [])

    useEffect(() => {
        const copyRadio = {...radio}
        if(store.site?.BTiene_AA !== undefined && store.site?.BTiene_AA !== null) {
            copyRadio.primary = store.site?.BTiene_AA ? true : false
        }

        if(store.site?.BActividad_Comercial !== undefined && store.site?.BActividad_Comercial !== null) {
            copyRadio.secondary = store.site?.BActividad_Comercial ? true : false
        }

        if(store.site?.SDetalle_Actividad !== undefined && store.site?.SDetalle_Actividad !== null) {
            setComercialActivity(store.site?.SDetalle_Actividad)
        }

        if(store.site?.BPanel_Solar !== undefined && store.site?.BPanel_Solar !== null) {
            copyRadio.third = store.site?.BPanel_Solar ? true : false
        }

        setRadio(copyRadio)
    }, [store.site])

    useEffect(() => {
        const docButton = document.getElementById('button-submit-atomic')
        if(radio.secondary) {
            if(comercialActivity !== '') {
                console.log('aaaa')
                docButton?.classList.remove('button-atomic-disabled')
                setButtonDisabled(false)
            } else {
                docButton?.classList.add('button-atomic-disabled')
                setButtonDisabled(true)
            }
            setShowComercialActivity(true)
        } else {
            docButton?.classList.remove('button-atomic-disabled')
            setButtonDisabled(false)
            setShowComercialActivity(false)
        }
    }, [radio.secondary, comercialActivity])

    const submit = async(event) => {
        event.preventDefault()
        try {
            await updateSite({ BTiene_AA: radio.primary ? 1 : 0, BActividad_Comercial: radio.secondary ? 1 : 0, SDetalle_Actividad: comercialActivity, BPanel_Solar: radio.third ? 1 : 0, ICodigo: parseInt(store.site.ICodigo)})
            await updateUser({path: 'upload-files/phase-1', ICodigo: parseInt(store.user?.ICodigo)})
            if(store.user?.contract) await billingSiigo();
            navigate("../../upload-files/phase-1")
        } catch(err) {
            console.error(err)
        }
    }

    return (
        <>
            <section className="phase-4">
                <p className="mb-1">Detalles importantes para <strong>ayudarte a ahorrar</strong></p>
                <p className="text mb-1">¿Tienes aire acondicionado?</p>
                <div className="d-flex justify-content-center mb-1">
                    <Radio id="radio-1" name="name-1.1" label="Sí" checked={radio.primary} onClick={() => setRadio({...radio, primary: true})} />
                    <Radio id="radio-1" name="name-1.2" label="No" checked={!radio.primary} onClick={() => setRadio({...radio, primary: false})} />
                </div>
                <p className="text mb-1">¿En tu hogar existe alguna actividad comercial? Por ejemplo, una tienda, venta de helados entre otros</p>
                <div className="d-flex justify-content-center mb-1">
                    <Radio id="radio-2" name="name-2.1" label="Sí" checked={radio.secondary} onClick={() => setRadio({...radio, secondary: true})} />
                    <Radio id="radio-2" name="name-2.2" label="No" checked={!radio.secondary} onClick={() => setRadio({...radio, secondary: false})} />
                </div>
                {
                    showComercialActivity && (
                        <div className="container-input input-group">
                            <input type="text" className="input form-control rounded-3 text-center" placeholder="Tipo de actividad" onChange={({target}) => setComercialActivity(target.value)} value={comercialActivity} />
                        </div>
                    )
                }
                <p className="text mb-1 mt-2">¿En tu hogar tienes paneles solares?</p>
                <div className="d-flex justify-content-center mb-2">
                    <Radio id="radio-3" name="name-3.1" label="Sí" checked={radio.third} onClick={() => setRadio({...radio, third: true})} />
                    <Radio id="radio-3" name="name-3.2" label="No" checked={!radio.third} onClick={() => setRadio({...radio, third: false})} />
                </div>
                <Button id="button-submit-atomic" disabled={buttonDisabled} onClick={submit} label="Siguiente" />
            </section>
            {
                store.user?.ICod_Plan === 2 ? (
                    <div className='container-progress-gratin'>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage'></span>
                        <span className='progress-stage progress-active'></span>
                        <span className='progress-stage'></span>
                    </div>
                ) : (  
                    <div className='container-progress-payment'>
                        <span className='progress-stage'></span>
                        <span className='progress-stage progress-active'></span>
                        <span className='progress-stage'></span>
                    </div>
                )
            }
        </>
    )
}