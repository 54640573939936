/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button, Coupon, Plan, SelectModal } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { useUser, useApp, useSelects, useSite, usePlan } from '../../../../../hooks';
import { emojiHappyEyesOpen } from '../../../../assets/icons';
import { useVerifications } from '../../../../../utils/verifications.utils';
import { error, MessageInfo } from '../../../../../services/message';
import Recaptcha from 'react-google-invisible-recaptcha';
import ReactPixel from 'react-facebook-pixel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export const Upgrade = ({unregisteredPayment = false, unregistered = false}) => {

    const messageNotRegister = <><p>Aún no estás registrado con nosotros, da clic <a href='https://greenbi.co/iniciar/'>aquí</a> para que selecciones tu plan e inicies tu proceso.</p></>
    const messageCodeDiscount = <><p>El cupón ingresado no es válido</p></>

    const { store, createUserWithEmailPayment, updateUserPayment, getUserWithReference } = useUser();
    const { createSiteSignIn } = useSite();
    const { clearData, setRefreshFn, hideLoading, showLoading } = useApp();
    const { verificationEmail } = useVerifications();
    const { getTypeDocuments, getCountries } = useSelects();
    const { getPlans, getPlanUpgrade, upgrade } = usePlan();
    
    // Cedula
    const [doc, setDoc] = useState('');
    const [textValidationDoc, setTextValidationDoc] = useState('');
    const [typesDocument, setTypesDocument] = useState([]);
    const [typeDocument, setTypeDocument] = useState('')
    const [showTypeDocument, setShowTypeDocument] = useState(false);

    // Plam
    const [plans, setPlans] = useState([])
    const [plan, setPlan] = useState({})

    // Address
    const [address, setAddress] = useState('')
    const [textValidationAddress, setTextValidationAddress] = useState('')
    const [ubicationsOriginal, setUbicationsOriginal] = useState([])
    const [ubications, setUbications] = useState([])
    const [ubication, setUbication] = useState({})
    const [showUbications, setShowUbications] = useState(false);

    // Payment
    const [paymentDeclined, setPaymentDeclined] = useState(false)

    // Coupon
    const [code, setCode] = useState('');
    const [business, setBusiness] = useState('');
    const [discount, setDiscount] = useState(0);
    const [priceWithCoupon, setPriceWithCoupon] = useState('');
    const [priceWithCouponPoints, setPriceWithCouponPoints] = useState(0);

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [email, setEmail] = useState('')
    const [showAlertInfo, setShowAlertInfo] = useState(false)

    const [textValidationEmail, setTextValidationEmail] = useState('')

    const [messageAlert, setMessageAlert] = useState(<></>)

    const recaptcha = useRef()

    const navigate = useNavigate();

    const fetchDataPaymentRejected = useCallback(async() => {
        let params = (new URL(window.document.location)).searchParams;
        let reference = params.get('reference');
        let status = params.get('status');
        if(status && reference) {
            await getUserWithReference(reference)
            setPaymentDeclined(true)
            setMessageAlert(messageNotRegister)
            error(`Transaccion ${status === 'DECLINED' ? 'rechazada' : status === 'VOIDED' ? 'anulada' : 'error'}`, 3000)
        }
        sessionStorage.removeItem('email')
    }, [])

    useEffect(() => {
        fetchDataPaymentRejected()
    }, [fetchDataPaymentRejected])

    useEffect(() => {
        if(JSON.stringify(store.user) !== '{}' && JSON.stringify(store.site) !== '{}' && ubicationsOriginal.length > 0) {
            setEmail(store.user.correo)
            // setDoc(store.user.SCedula)
            const objCity = ubicationsOriginal.find(element => element.ICodigo === store.site.ICod_Ciudad)
            setUbication(objCity)
            setAddress(store.site.SDireccion)
        }
    }, [store.user, store.site, ubicationsOriginal])

    useEffect(() => {
        setRefreshFn(false)
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('token')
        clearData()
    }, [])

    const fetchData = useCallback(() => {
        getTypeDocuments()
            .then((response) => {
                const resArray = response.data.map(element => ({...element, name: `${element.Descripcion_Corta} - ${element.SDescripcion}`}))
                const obj = resArray.find(element => element.SDescripcion === 'Cedula')
                setTypesDocument(resArray)
                setTypeDocument(obj)
            })
            .catch((err) => {
                console.log(err)
            })

        getCountries()
            .then((response) => {
                const resCities = response.data.cities.map(element => {
                    const departament = response.data.departaments.find(element2 => element2.ICodigo === element.ICod_departamento)
                    return {ICodigo: element.ICodigo,name: `${element.SCiudad.toLowerCase()} - ${departament.SDepartamento.toLowerCase()}`, value: element.ICodigo, idDepartament: element.ICod_departamento}
                })
                setUbicationsOriginal([...resCities])
                setUbications([...resCities])
            })
            .catch(err => {
                console.error(err)
            })
        
        // Wompi
        document.getElementById('button-submit-atomic-payment')?.classList.remove('button-atomic-disabled')
        const script = document.createElement("script");
        script.src = "https://checkout.wompi.co/widget.js";
        script.async = true;
        document.body.appendChild(script);

        getPlans([1, 4])
            .then(({data}) => {
                const resData = data.map(element => {
                    const discountR = element.SValor * .6
                    const pricePoints = pointsToThousands(element?.SValor)
                    const priceDiscountPoints = pointsToThousands(discountR)
                    return {...element, priceDiscount: discountR, priceDiscountStr: priceDiscountPoints, priceStr: pricePoints}
                })
                setPlan(resData[0])
                setPlans(resData)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        
        const arrDoc = doc.split('')
        const validationEmail = verificationEmail(email)
        const validateAddress = validationAddress(address)
        const docButton = document.getElementById('button-submit-atomic')

        if(arrDoc.length > 4 && validationEmail && JSON.stringify(ubication) !== '{}' && address.length >= 5 && validateAddress && docButton !== null && docButton !== undefined) {
            setButtonDisabled(false)
            docButton.classList.remove('button-atomic-disabled')
        } else {
            setButtonDisabled(true)
            docButton.classList.add('button-atomic-disabled')
        }

        // Email
        if(validationEmail) {
            setTextValidationEmail("")
        }

        // cedula
        if(arrDoc.length > 4) {
            setTextValidationDoc("")
        }

        // Address
        const resValidate = validationAddress(address)
        if(address.length >= 5 && resValidate) {
            setTextValidationAddress('')
        } else if(resValidate) {
            setTextValidationAddress('')
        } else if(address.length >= 5) {
            setTextValidationAddress('')
        }

    }, [email, doc, ubication, address])
    
    // Coupon
    useEffect(() => {
        if(discount > 0) {
            const amount = ((100 - discount) / 100) * plan.priceDiscount
            const amountPoints = pointsToThousands(amount)
            setPriceWithCouponPoints(amountPoints)
            setPriceWithCoupon(amount)
        } else {
            setPriceWithCouponPoints('')
            setPriceWithCoupon(0)
        }
    }, [discount, plan])

    // Email
    const handleChangeEmail = ({ target }) => {
        const value = target.value.toLowerCase()
        setEmail(value)
    }

    const onBlurEmail = () => {
        const validationEmail = verificationEmail(email)
        if(!validationEmail) {
            setTextValidationEmail("Escribe correctamente tu correo")
        }
    }

    const recaptchaFn = () => {
        recaptcha.current.callbacks.reset()
        recaptcha.current.callbacks.execute()
            .then(() => {
                let objPlan = {...plan}
                if(priceWithCoupon > 0) objPlan.priceDiscount = priceWithCoupon
                if(discount === 100) objPlan.priceDiscount = 0
                const objRegister = {
                    email: email,
                    typeDocument: typeDocument,
                    doc: doc,
                    ubication: ubication,
                    address: address,
                    plan: objPlan,
                    reference:  store.user.SReferenciaPago,
                    code: code
                }
                sessionStorage.setItem('dataRegister', JSON.stringify(objRegister))
                hideLoading()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Cedula
    const onBlurDoc = () => {
        const arr = doc.split('')
        if(arr.length <= 4) {
            setTextValidationDoc("La cantidad mínima de caracteres son 5")
        }
    }

    const handleChangeDoc = ({target}) => {
        setDoc(target.value)
    }

    const handleChangeTypeDoc = (value) => {
        setTypeDocument(value)
        setShowTypeDocument(false)
    }

    // Address
    useEffect(() => {
        setUbications(ubicationsOriginal)
    }, [showUbications])

    const handleChangeUbication = (value) => {
        setUbication(value)
        setShowUbications(false)
    }

    const handleChangeAddress = ({target}) => {
        setAddress(target.value)
    }

    const onBlurAddress = () => {
        const resValidate = validationAddress(address)
        if(address.length >= 5) {
            if(!resValidate) {
                setTextValidationAddress('Debe contener al menos un número')
            } else {
                setTextValidationAddress('')
            }
        } else {
            setTextValidationAddress('La cantidad mínima de caracteres son 5')
        }
    }

    const validationAddress = (value) => {
        const valueSplit = value.split('')
        return valueSplit.some(element => !isNaN(element) && element !== ' ')
    }

    // Payment
    const payment = async(reference, data, propToken) => {
        let checkout = new window.WidgetCheckout({
            currency: 'COP',
            amountInCents: data.plan.priceDiscount * 100,
            reference:  reference,
            publicKey: process.env.REACT_APP_PUBLIC_KEY_WOMPI,
            redirectUrl: `${process.env.REACT_APP_URL_FRONT}plan/phase-3?plan=${data.plan.ICodigo}`,
            customerData: {
                email: data.email,
                fullName: data.name, 
                phoneNumber: data.number,
                phoneNumberPrefix: '+57'
            }
        })
        ReactPixel.init('4515822518477786', {
            autoConfig: false
        });
        ReactPixel.pageView();
        ReactPixel.track('InitiateCheckout');
        if(paymentDeclined) {
            await getPlanUpgrade(data.plan.ICodigo, propToken)
            checkout.open( async ( result ) => {
                //Guardar en la base de datos
                let transaction = result.transaction
                if(transaction.status === "APPROVED") {
                    showLoading()
                    if(data.plan.ICodigo === 1) {
                        window.location="https://greenbi.co/iniciar-encoche/"
                    } else {
                        window.location="https://greenbi.co/iniciar-avion/"
                    }
                } else {
                    setMessageAlert(messageNotRegister)
                    await paymentDeclined({email: data.email, plan: data.plan.ICodigo, status: transaction.status}) 
                    console.error(`Transaccion ${transaction.status}`)
                }
            })
        } else {
            await getPlanUpgrade(data.plan.ICodigo, propToken)
            updateUserPayment({SReferenciaPago: reference}, propToken)
                .then(() => {
                    checkout.open( async ( result ) => {
                        //Guardar en la base de datos
                        let transaction = result.transaction
                        if(transaction.status === "APPROVED") {
                            showLoading()
                            if(data.plan.ICodigo === 1) {
                                window.location="https://greenbi.co/iniciar-encoche/"
                            } else {
                                window.location="https://greenbi.co/iniciar-avion/"
                            }
                        } else {
                            setMessageAlert(messageNotRegister)
                            await paymentDeclined({email: data.email, plan: data.plan.ICodigo, status: transaction.status}) 
                            console.error(`Transaccion ${transaction.status}`)
                        }
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const pointsToThousands = (number) => {
        let numberWithPoints = []
        const arrayNumber = JSON.stringify(number).split('')
        let counter = 0
        for(let i = (arrayNumber.length - 1); i >= 0; i--) {
            if(counter < 3) {
                numberWithPoints.unshift(arrayNumber[i])
                counter++
            } else {
                numberWithPoints.unshift(arrayNumber[i], '.')
                counter = 0
            }
        }
        return `$${numberWithPoints.toString().replaceAll(',', '')}`
    }

    const submit = () => {
        try{ 
            sessionStorage.removeItem('id')
            sessionStorage.removeItem('token')
            const dataRegister = sessionStorage.getItem('dataRegister')
            const dataRegisterParse = JSON.parse(dataRegister)
            setEmail(dataRegisterParse.email)
            setDoc(dataRegisterParse.doc)
            setUbication(dataRegisterParse.ubication)
            setAddress(dataRegisterParse.address)
            sessionStorage.removeItem('dataRegister')
            createUserWithEmailPayment({ SCedula: dataRegisterParse.doc, plan: dataRegisterParse.plan.ICodigo,email: dataRegisterParse.email, ICod_Tipo_Documento: dataRegisterParse.typeDocument.ICodigo, unregisteredPayment: unregisteredPayment, unregistered: unregistered, SCupon_Descuento: dataRegisterParse.code })
                .then((response) => {
                    if(response.status === 280) {
                        createSiteSignIn({ ICod_Ciudad: dataRegisterParse.ubication.ICodigo, SDireccion: dataRegisterParse.address, unregisteredPayment: unregisteredPayment}, response.token)
                            .then(async(resSite) => {
                                if(resSite.status === 280) {
                                    console.log(dataRegisterParse.plan.priceDiscount)
                                    sessionStorage.setItem('email', dataRegisterParse.email)
                                    sessionStorage.setItem('token', response.token)
                                    sessionStorage.setItem('id', response.id) 
                                    if(dataRegisterParse.plan.priceDiscount > 0) {
                                        dataRegisterParse.name = response.data.nombre
                                        dataRegisterParse.number = response.data.STelefono    
                                        const date = new Date()
                                        const string = date.toLocaleString()
                                        let reference = response.data.SReferenciaPago
                                        if(response.data.SReferenciaPago === null) reference = btoa(string)
                                        payment(reference, dataRegisterParse, response.token)
                                    } else {
                                        await upgrade(dataRegisterParse.plan.ICodigo, null, response.token)
                                        showLoading()
                                        if(dataRegisterParse.plan.ICodigo === 1) {
                                            window.location="https://greenbi.co/iniciar-encoche/"
                                        } else {
                                            window.location="https://greenbi.co/iniciar-avion/"
                                        }
                                    }
                                }
                            })
                            .catch((err) => {
                                console.error(err)
                            })
                    } else if(response.status === 235 || response.status === 236) {
                        setTextValidationEmail('El usuario cuenta con un plan pago activo')
                    } else if(response.status === 241) {
                        sessionStorage.setItem('route', `plan-${response.plan === 1 ? 'coche' : 'avion'}`)
                        sessionStorage.setItem('token', response.token)
                        if(response.path.includes('mode=')) {
                            window.location = `${process.env.REACT_APP_URL_FRONT}${response.path}`
                        } else {
                            navigate(`../../${response.path}`)
                        }
                    } else if(response.status === 242) {
                        sessionStorage.setItem('route', 'plan-gratin')
                        sessionStorage.setItem('token', response.token)
                        navigate(`../../${response.path}`)
                    } else if(response.status === 240) {
                        setMessageAlert(messageNotRegister)
                        setShowAlertInfo(true)
                    } else if(response.status === 270) {
                        setTextValidationDoc('Este documento ya cuenta con un registro')
                    } else if(response.status === 265) {
                        setMessageAlert(messageCodeDiscount)
                        error(`Codigo vencido`, 3000)
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        } catch(err) {
            console.error(err)
        }
    }

    return (
        <>
            <section className="upgrade">
                <img className='emoji' src={emojiHappyEyesOpen} alt="" />
                {
                    plans.map((element, index) => <Plan data={element} setPlan={setPlan} index={index} lineThrough={business !== ''} />)
                }
                <div className='border mb-2 mt-2'></div>
                <p className='title-sign-in-payment mb-1'>Datos para generar tu factura</p>
                <div className="form">

                    {/* email */}
                    <div className={`container-input input-group ${textValidationEmail !== '' ? 'mb-4' : 'mb-2'}`}>
                        <input id="input-email-register" type="text" className="form-control input" onBlur={() => onBlurEmail()} onChange={handleChangeEmail} placeholder="Ingresa el correo registrado" value={email} autoComplete='off' />
                        { textValidationEmail !== '' && <p className="message-error message-error-verification-email">{textValidationEmail}</p> }
                    </div>

                    {/* Cedula */}
                    <div className={`container-cedula position-relative ${textValidationDoc !== '' ? 'mb-4' : 'mb-2'}`}> 
                        <div className='w-100 position-relative'>
                            <Button className={"cancel-disabled-button w-100 button-select-modal"} onClick={() => setShowTypeDocument(true) } label={typeDocument.Descripcion_Corta} />
                            <FontAwesomeIcon className='icon-down' icon={faAngleDown} onClick={() => setShowTypeDocument(true) } />
                        </div>
                        <div className='container-select-modal'>
                            { showTypeDocument && <SelectModal data={typesDocument} className={'bottom-0'} selected={handleChangeTypeDoc} hideModal={() => setShowTypeDocument(false)} withoutCapitalize={true} /> }
                        </div>
                        <div className="container-input input-group">
                            <input type="text" className="input form-control rounded-5 text-center" maxLength={20} onBlur={onBlurDoc} onChange={handleChangeDoc} placeholder="Número de documento" value={doc} autoComplete='off' />
                        </div>
                        { textValidationDoc !== '' && <p className="message-error mt-2">{textValidationDoc}</p> }
                    </div>

                    {/* Address */}
                    <div className='container-address mb-2'>
                        <div className='w-100 position-relative'>
                            <Button className={"cancel-disabled-button w-100 button-select-modal text-capitalize"} onClick={() => setShowUbications(true) } label={JSON.stringify(ubication) !== '{}' ? ubication.name : 'Ciudad'} />
                            <FontAwesomeIcon className='icon-down' icon={faAngleDown} onClick={() => setShowUbications(true) } />
                        </div>
                        <div className='container-select-modal'>
                            { showUbications && <SelectModal data={ubications} dataOriginal={ubicationsOriginal} setData={setUbications} className={'select-modal-cities'} classNameOption={'text-capitalize'} selected={handleChangeUbication} hideModal={() => setShowUbications(false)} searchFilter={true} /> }
                        </div>
                    </div>
                    <div class={`input-gro position-relative container-address rounded-5 ${textValidationAddress !== '' ? 'mb-4' : 'mb-2'}`}>
                        <div className="container-input">
                            <input type="text" className={`input form-control rounded-5 text-center`} onBlur={onBlurAddress} onChange={handleChangeAddress} placeholder="Dirección" value={address} maxLength={50} autoComplete='off' />
                            { textValidationAddress !== '' && <p className="message-error message-error-address">{textValidationAddress}</p> }
                        </div>
                    </div>

                    <Coupon
                        code={code}
                        setCode={setCode}
                        business={business}
                        setBusiness={setBusiness}
                        discount={discount}
                        setDiscount={setDiscount}
                        price={priceWithCouponPoints}
                        setPrice={setPriceWithCouponPoints}
                        priceInitial={plan.priceDiscount}
                    />

                    <Button id={'button-submit-atomic'} style={{marginTop: '12px'}} onClick={recaptchaFn} label={`${priceWithCoupon > 0 ?  'Pagar' : 'Siguiente'}`} disabled={buttonDisabled} />
                    <Recaptcha
                        ref={recaptcha}
                        sitekey="6LcAe6kdAAAAAE9MfRhlYwgHmC4wBj1QaWTB7BTJ"
                        onResolved={submit}
                        badge='bottomleft'
                    />
                </div>
                <p className="text-center mt-2 text-terms-and-conditions" for="text-terminos-y-condiciones">Al hacer clic en siguiente, acepta nuestros <a className="d-inline link-terms-and-conditions" href={`https://energymaster.co/${process.env.REACT_APP_TERMINOS_CONDICIONES}`} download>Términos, Política de datos y Política de cookies.</a></p>
                {
                    showAlertInfo && (
                        <MessageInfo onClick={() => setShowAlertInfo(false)} title={'Pilas'} message={messageAlert} />
                    )
                }
            </section>
        </>
    )
}