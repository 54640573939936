import React from 'react';
import { Route, Routes } from 'react-router';
import { Page404, Phase2PL, Phase3PL } from '../components';

export const Plan = ({unregisteredPayment, bdb}) => {

    return (
        <>
            <section className="plan position-relative">

                <Routes>
                    <Route path="phase-2" element={<Phase2PL />} />
                    <Route path="phase-3" element={<Phase3PL />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>

            </section>
        </>
    )
}