import instance from '../services/axios';

export const UploadFileName = async(data) => {
    try {
        const token = sessionStorage.getItem('token')
        return await instance(`upload-fileName`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: data
        })
    } catch(err) {
        console.error(err)
    }
}