import * as React from "react";
import { useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./app.provider.scss";

export const AppProvider = ({ children }) => {
  const appStore = useSelector(({ app }) => app);

  return (
    <div className="app-provider-container">
      
      { children }

      {appStore.loading && (
        <div className="over-container">
          <Loader type="ThreeDots" color="white" height={60} width={60} />
        </div>
      )}
    </div>
  );
};