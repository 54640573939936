import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActiveCampaign, usePlan, useUser } from '../../../../../hooks';

export const Phase2PL = () => {

    const { getInfoTransactionWompiForId } = usePlan()
    const { updateUser, getUserWithReference } = useUser()
    const { completeCP } = useActiveCampaign()

    const navigate = useNavigate();

    const fetchData = useCallback(async() => {
        let params = (new URL(window.document.location)).searchParams;
        let id = params.get('id');
        await getInfoTransactionWompiForId(id)
            .then(async(response) => {
                if(response.data.data.status === 'APPROVED') {
                    await completeCP()
                    if(response.isUpgrade) {
                        if(response.path && !response.path.includes('basic-information')) {
                            await updateUser({path: response.path})
                        } else {
                            await updateUser({path: 'site-information/phase-1'})
                        }
                    } else {
                        await updateUser({path: 'site-information/phase-1'})
                    }
                    navigate('../../congratulations')
                } else {
                    const resUser = await getUserWithReference(response.data.data.reference)
                    navigate(`../../first-steps/phase-1?mode=plan-${resUser.data.user.ICod_Plan === 1 ? 'coche' : 'avion'}&status=${response.data.data.status}&reference=${response.data.data.reference}`)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])
    
    return (
        <>
        </>
    )
}