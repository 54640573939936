import { useDispatch, useSelector } from "react-redux";
import { setBack, setClearSite, setClearUser, setLoading, setRefresh, setRoute, setToken } from "../redux/actions.js";

export const useApp = () => {

  const dispatch = useDispatch();
  const store = useSelector((store) => store);

  const showLoading = () => dispatch(setLoading(true));
  const hideLoading = () => dispatch(setLoading(false));

  const getToken = (token) => dispatch(setToken(token))

  const setRefreshFn = (property) => dispatch(setRefresh(property));

  const getRoute = (property) => dispatch(setRoute(property));

  const trueBack = () => dispatch(setBack(true));
  const falseBack = () => dispatch(setBack(false));

  const clearData = () => {
    dispatch(setBack(false))
    dispatch(setToken(''))
    dispatch(setClearSite())
    dispatch(setClearUser())
  }

  return { dispatch, store, showLoading, hideLoading, setRefreshFn, getToken, trueBack, falseBack, clearData, getRoute };
};