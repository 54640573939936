import React, { useEffect } from 'react';
import { useApp } from '../../../../../hooks';

export const Page404 = () => {

    const { setRefreshFn } = useApp()

    useEffect(() => {
        setRefreshFn(true)
    }, [])

    return (
        <>
        </>
    )
}