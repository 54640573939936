import React, { useState, useEffect, useCallback } from 'react';
import { Register } from './containers';
import { Route, Routes, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useUser } from '../../hooks';

export const Main = () => {

    const { validateToken } = useUser()

    const [ruta, setRuta] = useState("");

    const { pathname} = useLocation();

    const fetchData = useCallback(async() => {
        // const token = sessionStorage.getItem('token');
        const route = sessionStorage.getItem('route');
        const token = await validateToken()
        if(token.status === 200) {
            if(route) {
                setRuta(route)
            } else {
                // window.location="https://greenbi.co/";
            }
        } else if(route) {
            window.location=`${process.env.REACT_APP_URL_FRONT}first-steps/phase-1?mode=continue`;
        } else {
            // window.location="https://greenbi.co/";
        }
    }, [])

    useEffect(() => {
        if(pathname === '/first-steps/phase-1') {
            let params = (new URL(window.document.location)).searchParams;
            let route = params.get('mode');
            sessionStorage.setItem('route', route)
            if(route) {
                setRuta(route)
            } else {
                // window.location="https://greenbi.co/"; 
            }
        } else if(pathname === '/first-steps/phase-2') {
            const route = sessionStorage.getItem('route');
            if(route) {
                setRuta(route)
            } else {
                // window.location="https://greenbi.co/";
            }
        } else {
            fetchData()
        }
        if(pathname === '/') {
            // window.location="https://greenbi.co/"; 
        }
    }, [fetchData])

    return (
        <Routes>
            <Route path="/*" element={<Register route={ruta} setRoute={setRuta} />}/>
        </Routes>
    )
}