import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import reducers from "./reducers";

const window = global.window;

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancer =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store =
  process.env.REACT_APP_ENV === "prod"
    ? createStore(persistedReducer, applyMiddleware(thunk))
    : createStore(persistedReducer, composeEnhancer(applyMiddleware(thunk)));
export const persistor = persistStore(store);