import React, { useEffect, useCallback} from 'react';
import { BasicInformation, Congratulations, CongratulationsSura, Plan, SignIn, SignInPayment, SignInPaymentSura, SiteInformation, Upgrade, UploadFiles } from './containers';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useApp, useUser } from '../../../../hooks';
import { logo, points } from '../../../assets/icons';
import { LogoWhite, Sura, SuraWhite } from '../../../assets/images';
import { Page404 } from './components';
import "./styles.scss";

export const Register = ({route, setRoute}) => {

    const { store, getUser } = useUser();
    const { setRefreshFn, falseBack } = useApp();

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const user = useCallback(() => {
        if(store.app.refresh) {
            let params = (new URL(window.document.location)).searchParams;
            let route = params.get('id');
            const token = sessionStorage.getItem('token')
            const currentRoute = sessionStorage.getItem('route')
            if(route) {

            } else if(pathname === '/first-steps/phase-1') {
            } else if(pathname === '/first-steps/phase-2') {
            } else if(!token) {
                navigate(`/first-steps/phase-1`)
            } else {
                getUser()
                    .then((response) => {
                        if(response.data.path !== '/first-steps/phase-1') {
                            sessionStorage.setItem('token', response.token)
                            if(currentRoute !== 'payment-gateway') {
                                if(response.data.ICod_Plan === 1) {
                                    sessionStorage.setItem('route', 'plan-coche')
                                    setRoute('plan-coche')
                                } else if(response.data.ICod_Plan === 2) {
                                    sessionStorage.setItem('route', 'plan-gratin')
                                    setRoute('plan-gratin')
                                } else if(response.data.ICod_Plan === 4) {
                                    sessionStorage.setItem('route', 'plan-avion')
                                    setRoute('plan-avion')
                                } else {
                                    sessionStorage.setItem('route', 'corporative-bdb')
                                    setRoute('corporative-bdb') 
                                }
                                if(pathname === '/plan/phase-1') {
                                    if(response.data.SEstadoPago === 'APPROVED') {
                                        navigate(`/${response.data.path}`)
                                    } 
                                } else {
                                    if(!store.app.back) {
                                        navigate(`/${response.data.path}`)
                                    } else if(pathname === `/${response.data.path}`) {
                                        falseBack()
                                    }
                                }
                            }
                        } 
                    })
                    .catch((err) => {
                        if(err?.message === 'No se proporcionó el token' || err?.response?.message === 'No se proporcionó el token') {
                            navigate('../../first-steps/phase-1')
                        }
                        console.error(err)
                    })
            }
            console.log(pathname)
            setRefreshFn(false)
        }
    }, [store.app.refresh])

    useEffect(() => {
        user()
    }, [user])

    return (
        <>
            <div className={`container-register ${(pathname === '/congratulations' || pathname === '/congratulations-sura') ? 'container-register-congratulations' : ''} w-100`}>
                <img className='logo' src={pathname === '/congratulations' ? LogoWhite : pathname === '/congratulations-sura' ? SuraWhite : route === 'sura' ? Sura : logo} alt="logo" />
                <main className="register">
                    {
                        route === 'plan-avion' ? (
                            <Routes>
                                <Route path="first-steps/*" element={<SignInPayment />} />
                                <Route path="congratulations" element={<Congratulations />} />
                                <Route path="basic-information" element={<BasicInformation />} />
                                <Route path="plan/*" element={<Plan />} />
                                <Route path="site-information/*" element={<SiteInformation />} />
                                <Route path="upload-files/*" element={<UploadFiles />} />
                                <Route path="*" element={<Page404 />} />
                            </Routes>
                        ) : route === 'plan-coche' ? (
                            <Routes>
                                <Route path="first-steps/*" element={<SignInPayment />} />
                                <Route path="congratulations" element={<Congratulations />} />
                                <Route path="basic-information" element={<BasicInformation />} />
                                <Route path="plan/*" element={<Plan />} />
                                <Route path="site-information/*" element={<SiteInformation />} />
                                <Route path="upload-files/*" element={<UploadFiles />} />
                                <Route path="*" element={<Page404 />} />
                            </Routes>
                        ) : route === 'plan-gratin' ? (
                            <Routes>
                                <Route path="first-steps/*" element={<SignIn />} />
                                <Route path="basic-information" element={<BasicInformation />} />
                                <Route path="site-information/*" element={<SiteInformation />} />
                                <Route path="upload-files/*" element={<UploadFiles />} />
                                <Route path="*" element={<Page404 />} />
                            </Routes>
                        ) : route === 'payment-gateway' ? (
                            <Routes>
                                <Route path="first-steps/*" element={<Upgrade unregisteredPayment={true} />} />
                                <Route path="plan/*" element={<Plan unregisteredPayment={true} />} />
                                <Route path="basic-information" element={<BasicInformation />} />
                                <Route path="site-information/*" element={<SiteInformation />} />
                                <Route path="upload-files/*" element={<UploadFiles />} />
                                <Route path="*" element={<Page404 />} />
                            </Routes>
                        ) : route === 'continue' ? (
                            <Routes>
                                <Route path="first-steps/*" element={<SignIn unregistered={true} />} />
                                <Route path="basic-information" element={<BasicInformation />} />
                                <Route path="plan/*" element={<Plan />} />
                                <Route path="site-information/*" element={<SiteInformation />} />
                                <Route path="upload-files/*" element={<UploadFiles />} />
                                <Route path="*" element={<Page404 />} />
                            </Routes>
                        ) : route === 'corporative-bdb' ? (
                            <Routes>
                                <Route path="first-steps/*" element={<SignInPayment bdb={true} />} />
                                {/* <Route path="congratulations" element={<Congratulations />} /> */}
                                <Route path="site-information/*" element={<SiteInformation bdb={true} />} />
                                <Route path="upload-files/*" element={<UploadFiles bdb={true} />} />
                                <Route path="*" element={<Page404 />} />
                            </Routes>
                        ) : route === 'sura' && (
                            <Routes>
                                <Route path="first-steps/*" element={<SignInPaymentSura bdb={true} setRoute={setRoute} />} />
                                <Route path="congratulations-sura" element={<CongratulationsSura />} />
                                <Route path="*" element={<Page404 />} />
                            </Routes>
                        )
                    }
                </main>
            </div>
            <div></div>
        </>
    )
}