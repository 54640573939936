import instance from '../services/axios';
import { useDispatch, useSelector } from "react-redux";
import { setSite, setUser, setVersion } from "../redux/actions.js";

export const useUser = () => {

    const dispatch = useDispatch();
    const store = useSelector((store) => store);

    const token = sessionStorage.getItem('token')

    const getUser = async (id) => {
        try {
            return await instance.get(`user`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    dispatch(setUser(response.data))
                    dispatch(setVersion(response.version))
                    return response
                })
        } catch(err) {
            console.log(err)
        }
    }

    const getUserWithReference = async (reference) => {
        try {
            return await instance.get(`user-reference/${reference}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    dispatch(setUser(response.data.user))
                    dispatch(setSite(response.data.site))
                    return response
                })
        } catch(err) {
            console.log(err)
        }
    }

    const getUserWithEmail = async (email) => {
        try {
            return await instance.get(`user-email/${email}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    if(response.status === 200) {
                        dispatch(setUser(response.data.user))
                        dispatch(setSite(response.data.site))
                    }
                    return response
                })
        } catch(err) {
            console.log(err)
        }
    }

    const updateUser = async (data) => {
        try {
            return await instance(`user`, {
                method: 'patch',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            })
        } catch(err) {
            console.log(err)
        }
    }

    const updateUserPayment = async (data, propToken) => {
        try {
            return await instance(`user`, {
                method: 'patch',
                headers: {
                    'Authorization': `Bearer ${propToken}`
                },
                data: data
            })
        } catch(err) {
            console.log(err)
        }
    }

    const createUserWithEmail = async(email) => {

        try{
            return await instance.post('auth/register', email)
        } catch(err) {
            console.error(err)
        }
    }

    const createUserWithEmailPayment = async(email) => {

        try{
            return await instance.post('auth/register-payment', email)
        } catch(err) {
            console.error(err)
        }
    }

    const verifyCode = async(id, req) => {

        try{
            return await instance(`auth/verify-code/${id}`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: req
            })
        } catch(err) {
            console.error(err)
        }
    }

    const refreshCode = async(id) => {
        try{
            return await instance.get(`auth/refresh-code/${id}`)
        } catch(err) {
            console.error(err)
        }
    }

    const signSocial = async(req) => {

        try{
            return await instance.post('auth/sign-social', req)
        } catch(err) {
            console.error(err)
        }
    }

    const addDataPreRegistro = async() => {
        try{
            return await instance.get(`preregistro`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        } catch(err) {
            console.error(err)
        }
    }

    const addDataPreRegistroFactura = async(idSite) => {
        try{
            return await instance.get(`preregistro-factura/${idSite}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        } catch(err) {
            console.error(err)
        }
    }

    const validateCedula = async(data) => {
        try {
            return await instance(`cedula`, {
                method: 'patch',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            })
        } catch(err) {
            console.log(err)
        }
    }

    const validateToken = async() => {
        try {
            return await instance.get(`auth/validate-token`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    return { store, getUser, getUserWithReference, getUserWithEmail, updateUser, updateUserPayment, createUserWithEmail, createUserWithEmailPayment, verifyCode, refreshCode, signSocial, addDataPreRegistro, validateCedula, addDataPreRegistroFactura, validateToken}
}