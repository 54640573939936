export const useVerifications = () => {

    const verificationEmail = (value) => {
        const expression = /^([\da-z_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/
        if (expression.exec(value)){
            return true
        } else {
            return false
        }
    }

    const styleInputRequired = (id) => {
        const doc = document.getElementById(id)
        doc.classList.add('input-required')
        setTimeout(() => {
            doc.classList.remove('input-required')
        }, 5000)
    }

    const onBlurInputDisabledStyle = (id) => {
        const doc = document.getElementById(id)
        doc.classList.remove('input-required')
    }

    const disableSelection = (id) => {
        window.onload = function() {
            var labels = document.getElementById(id);
            for (var i = 0; i < labels.length; i++) {
                disableSelectionFn(labels[i]);
            }
        };
        function disableSelectionFn(element) {
            if (typeof element.onselectstart != 'undefined') {
                element.onselectstart = function() { return false; };
            } else if (typeof element.style.MozUserSelect != 'undefined') {
                element.style.MozUserSelect = 'none';
            } else {
                element.onmousedown = function() { return false; };
            }
        }
    }

    return { verificationEmail, styleInputRequired, onBlurInputDisabledStyle, disableSelection }
}