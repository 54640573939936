import React from 'react';
import "./styles.scss";

export const RadioPersonality = ({id, name, icon = '', label = '', onChange = () => {}, value, className = '', classNameradioMarked = '', disabled, disabledMarked}) => {

    return (
        <>
            <div className={`container-radio-personality ${className ?? ''}`}>
                {
                    icon !== '' ? (
                        <img className="icon-radio-personality" src={icon} alt= {`Icono de proyecto ${label ?? ''}`}/>
                    ) : null
                }
                {
                    label !== '' ? (
                        <label className="label-radio-personality" for={`radio-personality-${label}`}>{label}</label>
                    ) : null
                }
                <div className="contain-input-radio-personality">
                    <input className={`input input-${disabled ? disabledMarked ? (classNameradioMarked ?? '') : 'disabled' : (classNameradioMarked ?? '')} ${disabledMarked ? 'disabled-non-pointer' : ''}`} type="radio" id={id} name={name} onChange={onChange} checked={value} disabled={disabled} />
                    <div className={`radio radio-container-${disabled ? disabledMarked ? (classNameradioMarked ?? '') : 'disabled' : (classNameradioMarked ?? '')}`}>
                        <div className={`contain-radio radio-${disabled ? disabledMarked ? (classNameradioMarked ?? '') : 'disabled' : (classNameradioMarked ?? '')}`}></div>
                    </div>
                </div>
            </div>
        </>
    )
}